import { useEffect } from "react";
import { Route } from "react-router-dom";
import { GENERAL_ROUTES } from "utils/routes.constants";

import { authService } from "services/auth";

import { ReactComponent as NoAccess } from "assets/images/errorScreen/noAccess.svg";

import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";

const LogoutRoute = () => {
  useEffect(() => {
    const logout = async () => {
      await authService.redirectToLogout();
    };
    logout();
  }, []);

  return null;
};

export const generalRoutes = [
  <Route
    key={GENERAL_ROUTES.NO_ACCESS}
    path={GENERAL_ROUTES.NO_ACCESS}
    element={
      <ErrorScreen
        title="Access Denied"
        description="You don’t have access to this account"
        image={<NoAccess />}
        imagePosition={ErrorScreenImagePosition.Top}
      />
    }
  />,
  <Route
    key={GENERAL_ROUTES.LOGOUT}
    path={GENERAL_ROUTES.LOGOUT}
    element={<LogoutRoute />}
  />,
];
