import { privateApi } from "../private";

const SUPPORT_ACCESS = (accountName: string) => {
  return `/supportAccess/${accountName}`;
};

export type SupportAccessAgentType = {
  accountName: string;
  account_role_names: string[];
  expires_at: string;
};

export type SupportAccessShortType = {
  expires_at: string;
};

type AlterSupportAccessInput = {
  account_role_names?: string[];
  expires_at?: string;
};

type CreateSupportAccessInput = {
  account_role_names: string[];
  expires_at?: string;
};

const getSupportAccess = async (accountName: string) => {
  const response = await privateApi.get<SupportAccessAgentType>(
    SUPPORT_ACCESS(accountName)
  );
  return response.data;
};

const getSupportAccessShort = async (accountName: string) => {
  const response = await privateApi.get<SupportAccessShortType>(
    `${SUPPORT_ACCESS(accountName)}:short`
  );
  return response.data;
};

const createSupportAccess = async (
  accountName: string,
  input: CreateSupportAccessInput
): Promise<SupportAccessAgentType> => {
  const response = await privateApi.post(SUPPORT_ACCESS(accountName), input);
  return response.data;
};

const editSupportAccess = async (
  accountName: string,
  input: AlterSupportAccessInput
) => {
  const response = await privateApi.put(SUPPORT_ACCESS(accountName), input);
  return response.data;
};

const revokeSupportAccess = async (accountName: string) => {
  const response = await privateApi.delete(SUPPORT_ACCESS(accountName));
  return response.data;
};

export const SupportAccessService = {
  getSupportAccess,
  getSupportAccessShort,
  createSupportAccess,
  editSupportAccess,
  revokeSupportAccess,
};
