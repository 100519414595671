import { useQuery } from "react-query";

import { authService } from "./index";

export const useClaims = () => {
  const { data } = useQuery({
    queryKey: ["claims"],
    queryFn: () => {
      return authService.getFireboltClaims();
    },
    suspense: true,
  });
  return data;
};
