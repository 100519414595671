import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  generatePath,
  matchPath,
  useParams,
} from "react-router-dom";
import { ROUTES } from "utils/routes.constants";

import { useAccounts } from "services/account/useAccounts";

import {
  getLatestAccount,
  saveLatestAccount,
} from "components/Account/latestAccount";
import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";

export const AccountRoute = () => {
  const latestAccount = getLatestAccount();
  const accounts = useAccounts();
  const { accountName } = useParams();

  const currentAccount = useCurrentParamsAccount();

  const chooseAccountRoute = matchPath(
    ROUTES.CHOOSE_ACCOUNT,
    window.location.pathname
  );

  useEffect(() => {
    if (accountName && latestAccount !== currentAccount.accountName) {
      saveLatestAccount(currentAccount.accountName);
    }
  });

  if (!accountName && !latestAccount && accounts.length > 1) {
    return (
      <Navigate
        to={generatePath(ROUTES.CHOOSE_ACCOUNT, {
          accountName: currentAccount.accountName,
        })}
      />
    );
  }

  if (!accountName && latestAccount && !chooseAccountRoute) {
    return <Navigate to={`/${currentAccount.accountName}/develop`} />;
  }

  if (!accountName && accounts.length === 1) {
    return <Navigate to={`/${currentAccount.accountName}/develop`} />;
  }

  if (accountName && accountName !== currentAccount.accountName) {
    return <Navigate to={`/${currentAccount.accountName}/develop`} />;
  }

  return <Outlet />;
};
