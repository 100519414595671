import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import { Account } from "services/account/account.types";
import { getAccounts } from "services/account/getAccounts";
import UserFriendlyAPIError from "services/environment/UserFriendlyAPIError";
import { systemEngineEnvironment } from "services/environment/systemEngine";

export const createAccount = async (
  name: string,
  region: string
): Promise<Account> => {
  try {
    await systemEngineEnvironment.execute(
      `CREATE ACCOUNT "${name}" WITH REGION = ?;`,
      {
        parameters: [region],
      }
    );

    const accounts = await getAccounts(name);

    if (!accounts.length) {
      throw new ErrorWithTranslationKey("accounts.form.create.error");
    }

    return accounts[0];
  } catch (e) {
    if (e instanceof UserFriendlyAPIError) {
      throw e;
    }

    throw new ErrorWithTranslationKey("accounts.form.create.error");
  }
};
