import { Route } from "react-router-dom";
import { ROUTES } from "utils/routes.constants";

import {
  AsyncAccountAdminConfigure,
  AsyncChooseAccount,
  AsyncConfigure,
  AsyncDemoTool,
  AsyncDevelopWorkspace,
  AsyncGovern,
} from "pages/pages";

import { withTitle } from "components/DocumentTitle/withTitle";

import { AccountRoute } from "./AccountRoute";
import { protectedRoute } from "./Protected";
import { adminRestricted } from "./adminRestricted";

const ProtectedAsyncDevelopWorkspace = protectedRoute(
  withTitle("title.workspace", AsyncDevelopWorkspace)
);

const ProtectedAsyncDemoTool = protectedRoute(
  withTitle("title.demo", AsyncDemoTool)
);

const ProtectedAsyncChooseAccount = withTitle(
  "title.choose_account",
  protectedRoute(AsyncChooseAccount)
);

const ProtectedAsyncConfigure = adminRestricted({
  OrganizationAdminComponent: protectedRoute(AsyncConfigure),
  AccountAdminComponent: protectedRoute(AsyncAccountAdminConfigure),
});

const ProtectedAsyncGovern = protectedRoute(AsyncGovern);

export const ProtectedAccountRoute = protectedRoute(AccountRoute);

export const protectedRoutes = [
  <Route
    key={ROUTES.ROOT}
    path={ROUTES.ROOT}
    element={<ProtectedAsyncDevelopWorkspace />}
  />,
  <Route
    key={ROUTES.DEVELOP}
    path={ROUTES.DEVELOP}
    element={<ProtectedAsyncDevelopWorkspace />}
  />,
  <Route
    key="configure"
    path="/:accountName?/configure/*"
    element={<ProtectedAsyncConfigure />}
  />,
  <Route
    key="govern"
    path="/:accountName?/govern/*"
    element={<ProtectedAsyncGovern />}
  />,
];

export const chooseAccountRoute = [
  <Route
    key={ROUTES.CHOOSE_ACCOUNT}
    path={ROUTES.CHOOSE_ACCOUNT}
    element={<ProtectedAsyncChooseAccount />}
  />,
];

export const demoToolRoute = [
  <Route
    key={ROUTES.DEMO_TOOL}
    path={ROUTES.DEMO_TOOL}
    element={<ProtectedAsyncDemoTool />}
  />,
];
