import { Paper, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useOrganization } from "hooks/useOrganization";
import { useHasAdminRole } from "services/auth/useHasAdminRole";
import { AWS_MARKETPLACE_URL } from "services/globals";
import { useOrganizationCredit } from "services/organization/useOrganizationCredit";

import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import { ReactComponent as TrialIcon } from "assets/icons/Trial.svg";

import MaterialButton, { ButtonSize, ButtonTemplate } from "components/Button";
import LoadingOverlap from "components/LoadingOverlap";
import { usePopover } from "components/Popover/usePopover";

import styles from "./styles.module.scss";

const CreditIndicatorContent = (props: { closePopover: () => void }) => {
  const { closePopover } = props;
  const { data: balance, isFetching } = useOrganizationCredit();
  const hasAdminRole = useHasAdminRole();
  const { t } = useTranslation();
  const amount = Math.floor(balance?.availableCredit ?? 0);
  const units = balance?.units;

  if (isFetching) {
    return (
      <div className={styles.paper}>
        <LoadingOverlap isLoading />
      </div>
    );
  }
  return (
    <div className={styles.paper}>
      {!!units &&
        t(`billing.credit_tooltip_${units}`, {
          availableCredit: amount,
        })}
      {hasAdminRole && (
        <MaterialButton
          text="Connect to AWS Marketplace"
          template={ButtonTemplate.Secondary}
          size={ButtonSize.Tiny}
          onClick={() => {
            document.location.href = AWS_MARKETPLACE_URL;
            closePopover();
          }}
          dataTestid="connect-aws"
          className={styles.editButton}
        />
      )}
      <button
        className={styles.closeButton}
        data-testid="close-modal-button"
      >
        <CloseIcon
          className={styles.closeIcon}
          onClick={closePopover}
          data-testid="close-icon"
        />
      </button>
    </div>
  );
};

const ActiveCreditIndicator = () => {
  const { anchorRef, open, openPopover, closePopover } = usePopover();
  const hasAdminRole = useHasAdminRole();
  if (!hasAdminRole) {
    return null;
  }

  return (
    <>
      <div
        onClick={openPopover}
        ref={anchorRef}
        className={styles.creditIcon}
      >
        <span className={styles.icon}>
          <TrialIcon />
        </span>
      </div>

      <Popover
        id="mouse-over-popover"
        anchorEl={anchorRef.current}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: 10,
        }}
        onClose={closePopover}
        disableRestoreFocus
      >
        <Paper>
          <CreditIndicatorContent closePopover={closePopover} />
        </Paper>
      </Popover>
    </>
  );
};

export const CreditIndicator = () => {
  const { data: organization } = useOrganization();
  if (!organization) {
    return null;
  }

  if (organization.isPaymentRegistered) {
    return null;
  }

  return <ActiveCreditIndicator />;
};
