import { useState } from "react";

import { resetLoginPassword } from "services/login/resetLoginPassword";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useResetPassword = ({
  closeMenu,
  loginName,
}: {
  closeMenu: () => void;
  loginName: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { putStatusMessage } = useStatusMessageQueue();
  const handleResetPassword = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await resetLoginPassword(loginName);
      putStatusMessage({
        type: StatusMessageType.Success,
        message: "Reset login password email was sent",
      });
    } catch (error) {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
      closeMenu();
    }
  };
  return {
    isLoading,
    handleResetPassword,
  };
};
