import { TextField } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import classnames from "classnames";
import omit from "lodash/omit";
import React, { useState } from "react";

import { ReactComponent as ErrorIcon } from "assets/icons/Exclamation.svg";

import styles from "./styles.module.scss";

type InputFieldProps = {
  type: string;
  name: string;
  label?: React.ReactNode;
  value?: string;
  error?: boolean;

  onChange?: (name: string, value: string) => void;

  onBlur?: (name: any, value: any) => any;
  onFocus?: (name: any, value: any) => any;
  onKeyDown?: (event: any) => any;
  onKeyPress?: (event: any) => any;
  onAutofill?: (name: string) => void;
  fullWidth?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  focused?: boolean;
  InputProps?: Partial<StandardInputProps>;
  inputProps?: StandardInputProps["inputProps"];
  testId?: string;
  inputRef?: (ref: HTMLInputElement) => void;
  helperText?: React.ReactNode;
  InputLabelProps?: any;
  errorText?: string;
  classes?: any;
  errorTextOverflow?: boolean;
  placeholder?: string;
  defaultValue?: string;
  showErrorContainer?: boolean;
  readOnly?: boolean;
};

type ErrorMessageProps = {
  errorText?: InputFieldProps["errorText"];
  testId?: InputFieldProps["testId"];
  errorTextOverflow?: InputFieldProps["errorTextOverflow"];
  addWrapper?: boolean;
};

export const ErrorMessage = (props: ErrorMessageProps) => {
  const error = (
    <div
      className={classnames(styles.errorTextContainer, {
        [styles.active]: !!props.errorText,
      })}
      title={props.errorText ? props.errorText : undefined}
      data-testid={props.testId ? `${props.testId}-error` : `input-field-error`}
    >
      <ErrorIcon className={styles.errorIcon} />
      <span
        className={classnames(styles.errorText, {
          [styles.overflowVisible]: !!props.errorTextOverflow,
        })}
      >
        {props.errorText}
      </span>
    </div>
  );

  return props.addWrapper ? (
    <div className={styles.errorHelperContainer}>{error}</div>
  ) : (
    error
  );
};

const InputField: React.FC<InputFieldProps> = props => {
  const {
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    onKeyPress,
    autoFocus,
    showErrorContainer = true,
  } = props;
  const [focused, setFocused] = useState(autoFocus);

  const testAttributes = props?.testId
    ? { "data-testid": `${props?.testId}` }
    : {};

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (onChange) {
      onChange(target.name, target.value);
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setFocused(false);
    if (onBlur) {
      onBlur(target.name, target.value);
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setFocused(true);
    if (onFocus) {
      onFocus(target.name, target.value);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = event => {
    if (onKeyDown) {
      onKeyDown(event);
    }
  };
  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = event => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const textFieldProps = omit(props, [
    "testId",
    "errorText",
    "errorTextOverflow",
    "showErrorContainer",
    "onAutofill",
    "onAutofillDrop",
  ]);

  return (
    <TextField
      classes={{ root: classnames(styles.root, props?.classes?.root) }}
      {...textFieldProps}
      helperText={showErrorContainer ? <ErrorMessage {...props} /> : undefined}
      FormHelperTextProps={{
        classes: { root: styles.errorHelperContainer },
        // @ts-ignore
        component: "div",
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: classnames(styles.inputRoot, props?.InputProps?.classes?.root),
          error: styles.inputError,
          input: styles.input,
        },
      }}
      InputLabelProps={{
        classes: {
          asterisk: styles.asterisk,
          root: styles.label,
        },
        shrink: props.value || focused ? undefined : false,
        ...props.InputLabelProps,
      }}
      // TODO: check if we actually need both props
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ...props.inputProps,
        ...testAttributes,
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onKeyPress={handleKeyPress}
      placeholder={props.placeholder}
      inputRef={props.inputRef}
      readOnly={props.readOnly}
      variant="standard"
    />
  );
};

export default InputField;
