export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const AUTH0_TARGET_AUDIENCE = process.env
  .REACT_APP_AUTH0_TARGET_AUDIENCE as string;

export const ENV_TLD = process.env.REACT_APP_ENVIRONMENT_TLD as string;

export const ENV_REDIRECT = (process.env.REACT_APP_ENVIRONMENT_REDIRECT ||
  process.env.REACT_APP_ENVIRONMENT_TLD) as string;

export const DEFAULT_ENVIRONMENT_LOGIN = process.env
  .REACT_APP_ENVIRONMENT_REDIRECT
  ? `https://${process.env.REACT_APP_ENVIRONMENT_REDIRECT}/login`
  : `https://${ENV_TLD}/login`;
