import { Suspense, useMemo } from "react";
import { Route, Routes, matchPath, useLocation } from "react-router-dom";
import { GENERAL_ROUTES, GUEST_ROUTES } from "utils/routes.constants";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { useAppVersionUpdateNotification } from "hooks/useAppVersionUpdateNotification";
import { ThemeProvider } from "themes/themeContext";

import { LoginHandle } from "pages/Auth/LoginHandle/LoginHandle";

import LoadingOverlap from "components/LoadingOverlap";
import { MainSidebarSkeleton } from "components/MainSidebar/MainSidebarSkeleton";
import { Onboarding } from "components/Onboarding/Onboarding";

import { Layout } from "./Layout";

import styles from "./styles.module.scss";

const AppInner: React.FC = () => {
  useAppVersionUpdateNotification();
  const location = useLocation();
  const flags = useAllFlags();

  const isIngestionWizardEnabled =
    flags[FeatureFlag.FireboltAppEnableIngestionWizard];

  const isGuestRoute = useMemo(() => {
    const guestRoutes = [
      ...Object.values(GUEST_ROUTES),
      ...Object.values(GENERAL_ROUTES),
    ];

    for (let i = 0; i < guestRoutes.length; i++) {
      const match = matchPath(guestRoutes[i], location.pathname);
      if (match) {
        return true;
      }
    }

    return false;
  }, [location.pathname]);

  return (
    <ThemeProvider forcedTheme={isGuestRoute ? "light" : undefined}>
      <Suspense
        fallback={
          <div className={styles.layout}>
            <div className={styles.app}>
              <MainSidebarSkeleton />
              <div className={styles.content}>
                <LoadingOverlap isLoading />
              </div>
            </div>
          </div>
        }
      >
        <LoginHandle>
          <Onboarding skip={!!isIngestionWizardEnabled}>
            <Routes>
              <Route
                path="*"
                element={<Layout />}
              />
            </Routes>
          </Onboarding>
        </LoginHandle>
      </Suspense>
    </ThemeProvider>
  );
};

export default AppInner;
