export const fetchVersion = () => {
  const regex = /<meta id="app-version" name="version" content="(\w+)"/;

  return fetch(`/`, {
    method: "GET",
    redirect: "follow",
    cache: "no-cache",
  })
    .then(response => response.text())
    .then(result => {
      return result.match(regex)?.[1];
    })
    .catch(() => {
      return Promise.resolve(null);
    });
};
