import { TFunction } from "i18next";
import _ from "lodash";
import { Formatter } from "utils/helpers/Format";

import { User, authService } from "services/auth";
import { Login } from "services/login/login.types";

import {
  ContactFormField,
  ERROR_TYPES,
  ErrorObject,
  Fields,
  REQUIRED_FIELDS,
  SeverityValues,
  VALIDATION_BY_FIELD,
} from "./constants";
import { Translations } from "./translations";

export function generateDisabledFields({
  login,
  user,
  accountNameDisabled,
}: {
  login?: Login;
  user?: User;
  accountNameDisabled: boolean;
}) {
  return {
    [ContactFormField.FirstName]: !!login?.firstName,
    [ContactFormField.LastName]: !!login?.lastName,
    [ContactFormField.Account]: accountNameDisabled,
    [ContactFormField.Email]: !!user?.email,
  };
}

const getValidationByLength = (inputName: string) =>
  VALIDATION_BY_FIELD[inputName as keyof typeof VALIDATION_BY_FIELD]?.maxLength;

const generateInitialInput = (inputName: string, input = "") => {
  const maxLength = getValidationByLength(inputName) || 0;

  return input.length > maxLength ? input.substr(0, maxLength) : input;
};

export function generateInitialState({
  preCompletedFields,
  user,
  login,
  accountName,
  defaultEngineName,
}: {
  preCompletedFields?: Fields;
  user?: User;
  login?: Login;
  accountName: string;
  defaultEngineName?: string;
}) {
  return {
    [ContactFormField.FirstName]: generateInitialInput(
      ContactFormField.FirstName,
      preCompletedFields?.[ContactFormField.FirstName] || login?.firstName
    ),
    [ContactFormField.LastName]: generateInitialInput(
      ContactFormField.LastName,
      preCompletedFields?.[ContactFormField.LastName] || login?.lastName
    ),
    [ContactFormField.Account]: generateInitialInput(
      ContactFormField.Account,
      preCompletedFields?.[ContactFormField.Account] || accountName
    ),
    [ContactFormField.Organization]: generateInitialInput(
      ContactFormField.Organization,
      authService.organizationName
    ),
    [ContactFormField.Email]: generateInitialInput(
      ContactFormField.Email,
      preCompletedFields?.[ContactFormField.Email] || user?.email
    ),
    [ContactFormField.Severity]: SeverityValues.S3,
    [ContactFormField.EngineName]: "",
    [ContactFormField.Subject]:
      preCompletedFields?.[ContactFormField.Subject] || "",
    [ContactFormField.Description]: "",
    [ContactFormField.EngineName]: defaultEngineName,
  };
}

export function prepareDataForRequest(data: any) {
  const formData = {
    [ContactFormField.Origin]: "Web",
    [ContactFormField.Name]: `${data[ContactFormField.FirstName]} ${
      data[ContactFormField.LastName]
    }`,
    [ContactFormField.Account]: data[ContactFormField.Account],
    [ContactFormField.Organization]: data[ContactFormField.Organization],
    [ContactFormField.Email]: data[ContactFormField.Email],
    [ContactFormField.Subject]: data[ContactFormField.Subject],
    [ContactFormField.Description]: data[ContactFormField.Description],
    [ContactFormField.Severity]: data[ContactFormField.Severity],
    [ContactFormField.EngineName]: data[ContactFormField.EngineName],
  };
  return JSON.stringify(formData);
}

export function canSubmit(fields: any) {
  // check if all required fields have values
  const emptyRequiredFields = REQUIRED_FIELDS.find(field => !fields[field]);
  if (emptyRequiredFields) return false;

  // validate if the form is valid
  const isValid = _.filter(
    VALIDATION_BY_FIELD,
    (fieldRules: any, fieldName: string) => {
      const fieldValue = fields[fieldName];
      if (fieldRules.validate && !fieldRules.validate(fieldValue)) {
        return true;
      }
      return fieldRules.maxLength && fieldValue.length > fieldRules.maxLength;
    }
  );
  return _.isEmpty(isValid);
}

export function getErrorFromField(fieldName: string, fieldValue: string) {
  if (REQUIRED_FIELDS.includes(fieldName as ContactFormField) && !fieldValue) {
    return {
      fieldName,
      errorType: ERROR_TYPES.Empty,
    };
  }

  const fieldRules: any =
    VALIDATION_BY_FIELD[fieldName as keyof typeof VALIDATION_BY_FIELD];

  if (fieldRules) {
    if (fieldRules.validate && !fieldRules.validate(fieldValue)) {
      return {
        fieldName,
        errorType: ERROR_TYPES.InvalidField,
        inputForErrorMessage:
          Translations[fieldName as keyof typeof Translations],
      };
    }

    if (fieldRules.maxLength && fieldValue.length > fieldRules.maxLength) {
      return {
        fieldName,
        errorType: ERROR_TYPES.MaxLength,
        inputForErrorMessage: Formatter.formatNumberToLocale(
          `${fieldRules.maxLength}`
        ),
      };
    }
  }

  return null;
}

export function getAllErrors(fields: any) {
  const keys = Object.keys(fields);
  const errors: ErrorObject[] = [];

  keys.forEach(key => {
    const error = getErrorFromField(key as ContactFormField, fields[key]);
    if (error) {
      errors.push(error);
    }
  });

  return errors;
}

export function getContactSupportURL() {
  const CONTACT_SUPPORT_URI = "services/data/v53.0/sobjects/Case";
  return process.env.REACT_APP_SALESFORCE_ENDPOINT_URL + CONTACT_SUPPORT_URI;
}

export function getBackendErrorMessage(data: any, t: TFunction) {
  const errorCode = data?.[0]?.errorCode as keyof typeof Translations;
  const field = data?.[0]?.fields?.[0] as keyof typeof Translations;

  if (!Translations[errorCode]) {
    return;
  }

  return t(Translations[errorCode], { field: t(Translations[field]) });
}
