import { useRef } from "react";

const getActiveIndex = (
  list: HTMLCollection,
  activeElement = document.activeElement
) => {
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element === activeElement) {
      return i;
    }
  }
  return -1;
};

const PAGE_SIZE = 7;
const FIRST_ELEMENT_INDEX = 0;

export const useKeyboardFocus = (pageSize = PAGE_SIZE) => {
  const menuRef = useRef<HTMLUListElement | null>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const lastActiveElement = useRef<Element | null>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const menuList = menuRef?.current?.children;
    switch (event.key) {
      case "PageUp": {
        event.preventDefault();
        if (menuList) {
          const activeIndex = getActiveIndex(menuList);
          if (activeIndex !== -1) {
            const nextIndex = Math.max(
              activeIndex - pageSize,
              FIRST_ELEMENT_INDEX
            );
            const nextActiveElement = menuList[nextIndex];
            (nextActiveElement as HTMLElement).focus();
          }
        }
        lastActiveElement.current = document.activeElement;
        break;
      }
      case "PageDown": {
        event.preventDefault();
        if (menuList) {
          const activeIndex = getActiveIndex(menuList);
          if (activeIndex !== -1) {
            const nextIndex = Math.min(
              activeIndex + pageSize,
              menuList.length - 1
            );
            const nextActiveElement = menuList[nextIndex];
            (nextActiveElement as HTMLElement).focus();
          }
        }
        lastActiveElement.current = document.activeElement;
        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        if (menuList) {
          const activeIndex = getActiveIndex(menuList);
          if (activeIndex === 0 || activeIndex === -1) {
            const nextActiveElement = menuList[FIRST_ELEMENT_INDEX];
            (nextActiveElement as HTMLElement).focus();
          }
        }
        lastActiveElement.current = document.activeElement;
        break;
      }
      case "ArrowUp": {
        if (menuList) {
          const searchElement = searchRef.current;
          const topMenuElement = menuList[FIRST_ELEMENT_INDEX];
          if (topMenuElement === lastActiveElement.current && searchElement) {
            const inputElement = searchElement.querySelector("input");
            inputElement && (inputElement as HTMLElement)?.focus();
            menuRef.current?.scrollIntoView();
          } else {
            lastActiveElement.current = document.activeElement;
            document.activeElement?.scrollIntoView({ block: "center" });
          }
        }
        break;
      }
      case "End":
      case "Home": {
        lastActiveElement.current = document.activeElement;
        break;
      }

      default:
    }
  };

  return {
    listRef: menuRef,
    searchRef,
    handleKeyDown,
  };
};
