import { createBrowserRouter } from "react-router-dom";

import AppInner from "./App/AppInner";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <AppInner />,
  },
]);
