import { SupportAgentCreate } from "./SupportAgentCreate";
import { SupportAgentEdit } from "./SupportAgentEdit";
import { SupportAgentMenu } from "./SupportAgentMenu";

export const SupportAgentSteps = {
  create: SupportAgentCreate,
  edit: SupportAgentEdit,
  menu: SupportAgentMenu,
};

export type SupportAgentStepsEnum = keyof typeof SupportAgentSteps;
