import { FormControl } from "@mui/material";
import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { useLoggedInUser } from "hooks/useLoggedInUser";

import InputField, { ErrorMessage } from "components/Input";
import TextArea from "components/TextArea/TextArea";

import {
  ContactFormField,
  DisabledFields,
  ErrorFields,
  Fields,
  TEST_IDS,
} from "../constants";
import { Translations } from "../translations";
import Account from "./Account";
import Severity from "./Severity";
import SplitRow from "./SplitRow";

import styles from "../ContactSupport.module.scss";

interface Props {
  fields: Fields;
  disabledFields: DisabledFields;
  errors: ErrorFields;
  handleChange: (name: string, value: string) => void;
}

const Form: React.FunctionComponent<Props> = (props: Props) => {
  const { fields, disabledFields, handleChange, errors } = props;
  const { isLoggedIn } = useLoggedInUser();

  const { t } = useTranslation();
  return (
    <>
      <SplitRow
        cols={[
          <InputField
            key="firstName"
            testId={TEST_IDS.firstNameInput}
            type="text"
            label={t(Translations[ContactFormField.FirstName])}
            name={ContactFormField.FirstName}
            value={fields[ContactFormField.FirstName]}
            disabled={disabledFields[ContactFormField.FirstName]}
            onChange={handleChange}
            errorText={errors[ContactFormField.FirstName]}
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk,
              },
            }}
            fullWidth
            required={!isLoggedIn}
            autoFocus
          />,
          <InputField
            key="lastName"
            testId={TEST_IDS.lastNameInput}
            type="text"
            label={t(Translations[ContactFormField.LastName])}
            name={ContactFormField.LastName}
            value={fields[ContactFormField.LastName]}
            disabled={disabledFields[ContactFormField.LastName]}
            onChange={handleChange}
            errorText={errors[ContactFormField.LastName]}
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk,
              },
            }}
            fullWidth
            required={!isLoggedIn}
          />,
        ]}
      />
      <SplitRow
        cols={[
          <InputField
            key="organization"
            type="text"
            label={t(Translations[ContactFormField.Organization])}
            name={ContactFormField.Organization}
            value={fields[ContactFormField.Organization]}
            disabled={true}
            onChange={handleChange}
            fullWidth
          />,
          <InputField
            key="email"
            testId={TEST_IDS.emailInput}
            type="text"
            label={t(Translations[ContactFormField.Email])}
            name={ContactFormField.Email}
            value={fields[ContactFormField.Email]}
            disabled={disabledFields[ContactFormField.Email]}
            onChange={handleChange}
            errorText={errors[ContactFormField.Email]}
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk,
              },
            }}
            fullWidth
            required={!isLoggedIn}
          />,
        ]}
      />
      <SplitRow
        cols={[
          <div
            className={styles.select}
            key="account"
          >
            <Account
              fields={fields}
              disabledFields={disabledFields}
              handleChange={handleChange}
              autoFocus={!disabledFields[ContactFormField.Account]}
            />
          </div>,
          <InputField
            key="engine"
            testId={TEST_IDS.engineNameInput}
            type="text"
            label={t(Translations[ContactFormField.EngineName])}
            name={ContactFormField.EngineName}
            value={fields[ContactFormField.EngineName]}
            disabled={disabledFields[ContactFormField.EngineName]}
            onChange={handleChange}
            errorText={errors[ContactFormField.EngineName]}
            fullWidth
          />,
        ]}
      />
      <SplitRow
        cols={[
          <div
            className={styles.select}
            key="severity"
          >
            <Severity
              fields={fields}
              disabledFields={disabledFields}
              handleChange={handleChange}
              autoFocus={disabledFields[ContactFormField.Account]}
            />
          </div>,
        ]}
      />

      <div className={styles.row}>
        <InputField
          testId={TEST_IDS.subjectInput}
          type="text"
          label={t(Translations[ContactFormField.Subject])}
          name={ContactFormField.Subject}
          value={fields[ContactFormField.Subject]}
          disabled={disabledFields[ContactFormField.Subject]}
          onChange={handleChange}
          errorText={errors[ContactFormField.Subject]}
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          fullWidth
          required
        />
      </div>
      <div className={cn(styles.row, styles.mb30)}>
        <FormControl fullWidth>
          <div className={styles.label}>
            {t(Translations[ContactFormField.Description])}
          </div>
          <TextArea
            name={ContactFormField.Description}
            testId={TEST_IDS.descriptionInput}
            className={styles.description}
            placeholder={t(Translations.descriptionPlaceholder) as string}
            value={fields[ContactFormField.Description]}
            disabled={disabledFields[ContactFormField.Description]}
            onChange={e =>
              handleChange(ContactFormField.Description, e.target.value)
            }
          />
          <ErrorMessage
            errorText={errors[ContactFormField.Description]}
            addWrapper
          />
        </FormControl>
      </div>
    </>
  );
};

export default Form;
