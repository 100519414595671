import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import StatusMessageQueueView from "components/StatusMessageQueue/StatusMessageQueueView/StatusMessageQueueView";

// create context
export const StatusMessageQueueContext = React.createContext<
  StatusMessageQueueContextValue | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
  onInit?: (instance: StatusMessageQueueContextValue) => void;
}

export enum StatusMessagePosition {
  Top = "TOP",
  Bottom = "BOTTOM",
}

export interface StatusMessageOptions {
  insertToPosition: StatusMessagePosition;
  id: string | null;
  autoRemove: boolean;
  preventAutoClear?: boolean;
  autoClearDelay?: number;
  timestamp?: number;
  preventDeletionOnReset?: boolean; // To remove the message you need manually dispatch REMOVE_MESSAGE
  testId?: string;
}

export enum StatusMessageType {
  Success = "success",
  Error = "error",
  Loading = "loading",
}

export interface StatusMessage {
  message: string | ReactNode;
  type: StatusMessageType;
  options?: StatusMessageOptions;
}

export enum StatusMessageAction {
  PUT_MESSAGE_SUCCESS = "STATUS_MESSAGE/PUT_MESSAGE_SUCCESS",
  REMOVE_MESSAGE = "STATUS_MESSAGE/REMOVE_MESSAGE",
  REMOVE_MESSAGE_BY_TIMESTAMP = "STATUS_MESSAGE/REMOVE_MESSAGE_BY_TIMEOUT_ID",
  RESET = "STATUS_MESSAGE/RESET",
}

const addMessage = (
  messages: StatusMessage[],
  newMessage: StatusMessage
): StatusMessage[] => {
  if (newMessage?.options?.insertToPosition === StatusMessagePosition.Top) {
    return [newMessage, ...messages];
  }

  return [...messages, newMessage];
};

const STATUS_BAR_MAX_MESSAGES_NUMBER = 3;
const DEFAULT_AUTOCLEAR_MS = 5000;

interface State {
  messages: StatusMessage[];
}

export interface StatusMessageQueueContextValue {
  putStatusMessage: (message: StatusMessage) => void;
  removeStatusMessage: (messageId: string) => void;
  resetStatusMessages: () => void;
}

const ACTIONS = {
  [StatusMessageAction.REMOVE_MESSAGE]: (state: State, payload: string) => {
    const id = payload as string;

    if (!id) {
      return state;
    }

    return {
      ...state,
      messages: state.messages.filter(msg => msg?.options?.id !== id),
    };
  },
  [StatusMessageAction.REMOVE_MESSAGE_BY_TIMESTAMP]: (
    state: State,
    payload: any
  ) => {
    const timestamp = payload as number;

    if (!timestamp) {
      return state;
    }

    return {
      ...state,
      messages: state.messages.filter(
        msg => msg?.options?.timestamp !== timestamp
      ),
    };
  },
  [StatusMessageAction.RESET]: (state: State) => {
    return {
      ...state,
      messages: state.messages.filter(
        msg => msg?.options?.preventDeletionOnReset
      ),
    };
  },
  [StatusMessageAction.PUT_MESSAGE_SUCCESS]: (state: State, payload: any) => {
    const { options } = payload as StatusMessage;

    if (options?.preventDeletionOnReset && !options.id) {
      console.warn(
        "PUT_MESSAGE_SUCCESS was ignored. Message ID must be defined if preventDeletionOnReset used"
      );
      return state;
    }

    // check if needed to replace message
    if (options?.id) {
      const foundMessage = state.messages.find(
        message => message?.options?.id === options.id
      );

      if (foundMessage) {
        return {
          ...state,
          messages: state.messages.map(message => {
            if (message?.options?.id === options.id) {
              return payload;
            }

            return message;
          }),
        };
      }
    }

    // reduce messages with autoRemove
    const filteredMessages = state.messages.filter(sMessage => {
      if (sMessage?.options?.autoRemove) {
        return false;
      }

      return true;
    });

    const newMessages = addMessage(filteredMessages, payload as StatusMessage);

    // check list overflow
    if (newMessages.length > STATUS_BAR_MAX_MESSAGES_NUMBER) {
      if (options?.insertToPosition === StatusMessagePosition.Top) {
        newMessages.pop();
      }
      if (options?.insertToPosition === StatusMessagePosition.Bottom) {
        newMessages.shift();
      }
    }

    return { ...state, messages: newMessages };
  },
};

const StatusMessageQueueProvider = (props: Props) => {
  const { onInit } = props;
  const timers = useRef<NodeJS.Timeout[]>([]);

  /* eslint-disable */
  useEffect(() => {
    return () => {
      timers.current.forEach(timer => clearTimeout(timer));
    };
  }, []);
  /* eslint-enable */

  const [state, dispatch] = useReducer(
    (
      state: State,
      action: {
        type: StatusMessageAction;
        payload: any;
      }
    ) => {
      const actionReducer = ACTIONS[action.type];

      if (!actionReducer) {
        return state;
      }

      return actionReducer(state, action.payload);
    },
    {
      messages: [],
    }
  );

  const removeStatusMessageByTimestamp = useCallback(
    (timestamp: number) => {
      dispatch({
        type: StatusMessageAction.REMOVE_MESSAGE_BY_TIMESTAMP,
        payload: timestamp,
      });
    },
    [dispatch]
  );

  const putStatusMessage = useCallback(
    (payload: StatusMessage) => {
      const { message, type, options } = payload;

      const timestamp = Date.now();

      dispatch({
        type: StatusMessageAction.PUT_MESSAGE_SUCCESS,
        payload: {
          message,
          type,
          options: {
            ...(options as StatusMessageOptions),
            timestamp: Date.now(),
          },
        },
      });

      if (
        options?.preventAutoClear ||
        ![StatusMessageType.Success, StatusMessageType.Error].includes(type)
      ) {
        return;
      }

      const timerId = setTimeout(
        () => {
          removeStatusMessageByTimestamp(timestamp);
        },
        options?.autoClearDelay || DEFAULT_AUTOCLEAR_MS
      );

      timers.current.push(timerId);
    },
    [dispatch, removeStatusMessageByTimestamp]
  );

  const removeStatusMessage = useCallback(
    (messageId: string) => {
      dispatch({
        type: StatusMessageAction.REMOVE_MESSAGE,
        payload: messageId,
      });
    },
    [dispatch]
  );

  const resetStatusMessages = useCallback(() => {
    dispatch({
      type: StatusMessageAction.RESET,
      payload: null,
    });
  }, [dispatch]);

  const value = useMemo<StatusMessageQueueContextValue>(() => {
    const val = {
      putStatusMessage,
      removeStatusMessage,
      resetStatusMessages,
    };

    if (onInit) {
      onInit(val);
    }

    return val;
  }, [putStatusMessage, resetStatusMessages, removeStatusMessage, onInit]);

  return (
    <StatusMessageQueueContext.Provider value={value}>
      {props.children}
      {state && (
        <StatusMessageQueueView messages={state.messages as StatusMessage[]} />
      )}
    </StatusMessageQueueContext.Provider>
  );
};

export default StatusMessageQueueProvider;
