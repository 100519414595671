import { useEffect } from "react";
import { useQuery } from "react-query";

import { useHasAccountAdminRole } from "services/auth/useHasAccountAdminRole";
import { useHasAdminRole } from "services/auth/useHasAdminRole";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import {
  SUPPORT_ACCESS_QUERY_KEY,
  SUPPORT_ACCESS_SHORT_QUERY_KEY,
} from "components/SupportAgent/constants";

import { SupportAccessService } from "./index";

const ONE_MINUTE = 60 * 1000;

const useInvalidateOnExpire = ({
  data = [],
  refetch,
}: {
  data?: { expires_at: string }[];
  refetch: ReturnType<typeof useQuery>["refetch"];
}) => {
  useEffect(() => {
    const timer = setInterval(() => {
      const expired = data.some(agent => {
        const expire = new Date(agent.expires_at);
        if (expire < new Date()) {
          return true;
        }
        return false;
      });

      if (expired) {
        refetch();
      }
    }, ONE_MINUTE);

    return () => {
      clearInterval(timer);
    };
  }, [data, refetch]);
};

export const useSupportAccess = () => {
  const currentAccount = useCurrentParamsAccount();
  const hasAccountAdminRole = useHasAccountAdminRole();
  const hasAdminRole = useHasAdminRole();
  const { data, refetch } = useQuery({
    queryKey: [SUPPORT_ACCESS_QUERY_KEY],
    queryFn: async () => {
      try {
        if (hasAdminRole || hasAccountAdminRole) {
          return await SupportAccessService.getSupportAccess(
            currentAccount.accountName
          );
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    suspense: true,
  });
  useInvalidateOnExpire({ data: data ? [data] : [], refetch });
  return data;
};

export const useSupportAccessShort = () => {
  const currentAccount = useCurrentParamsAccount();
  const hasAccountAdminRole = useHasAccountAdminRole();
  const hasAdminRole = useHasAdminRole();
  const { data, refetch } = useQuery({
    queryKey: [SUPPORT_ACCESS_SHORT_QUERY_KEY],
    queryFn: async () => {
      try {
        if (hasAdminRole || hasAccountAdminRole) {
          return await SupportAccessService.getSupportAccessShort(
            currentAccount.accountName
          );
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    suspense: true,
  });
  useInvalidateOnExpire({ data: data ? [data] : [], refetch });
  return data;
};
