import { useQuery } from "react-query";

import { getMyTenants } from "./getMyTenants";
import { MyTenant } from "./types";

export const useMyTenants = () => {
  const { data } = useQuery<MyTenant[], Error>({
    queryKey: ["myTenants"],
    queryFn: () => getMyTenants(),
    retryOnMount: false,
  });
  return data || [];
};
