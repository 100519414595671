import { useQuery } from "react-query";

import { authService } from "services/auth";

export const useLoggedInUser = () => {
  const { data } = useQuery({
    queryKey: "isLoggedIn",
    queryFn: () => {
      return authService.isLoggedIn();
    },
    suspense: true,
  });

  return {
    isLoggedIn: data,
  };
};
