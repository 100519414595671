import { Route } from "react-router-dom";
import { AWS_ROUTES } from "utils/routes.constants";

import { AsyncCreateOrganization } from "pages/pages";

import { AlreadyLinked, LinkingFailure } from "../aws";

export const awsRoutes = [
  <Route
    key={AWS_ROUTES.GETTING_STARTED}
    path={AWS_ROUTES.GETTING_STARTED}
    element={<AsyncCreateOrganization />}
  />,
  <Route
    key={AWS_ROUTES.LINK_ALREADY_LINKED}
    path={AWS_ROUTES.LINK_ALREADY_LINKED}
    element={<AlreadyLinked />}
  />,
  <Route
    key={AWS_ROUTES.LINK_FAILURE}
    path={AWS_ROUTES.LINK_FAILURE}
    element={<LinkingFailure />}
  />,
];
