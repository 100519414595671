import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import { Account } from "services/account/account.types";
import { getAccounts } from "services/account/getAccounts";
import UserFriendlyAPIError from "services/environment/UserFriendlyAPIError";
import { systemEngineEnvironment } from "services/environment/systemEngine";

export const updateAccount = async (
  oldName: string,
  newName: string
): Promise<Account> => {
  try {
    await systemEngineEnvironment.execute(
      `ALTER ACCOUNT "${oldName}" RENAME TO "${newName}";`
    );

    const accounts = await getAccounts(newName);

    if (!accounts.length) {
      throw new ErrorWithTranslationKey("accounts.form.update.error");
    }

    return accounts[0];
  } catch (e) {
    if (e instanceof UserFriendlyAPIError) {
      throw e;
    }

    throw new ErrorWithTranslationKey("accounts.form.update.error");
  }
};
