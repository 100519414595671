export const statusCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const SUPPORT_EMAIL = "support@firebolt.io";

export const SYSTEM_ACTOR = "Firebolt System";
export const DEFAULT_REGION_NAME = "us-east-1";
export const FIREBOLT_DOCS_URL = "https://docs.firebolt.io/godocs";

export const FIREBOLT_RELEASE_NOTES_URL_NEW =
  "https://docs.firebolt.io/godocs/Reference/release-notes/release-notes";

export const FIREBOLT_STATUS_PAGE_URL = "https://firebolt.statuspage.io/";

export const SYSTEM_ENGINE_DOCS_URL = `${FIREBOLT_DOCS_URL}/Guides/operate-engines/system-engine.html`;
