import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import {
  Organization,
  OrganizationRaw,
} from "pages/organization/organization.types";

export const getOrganization = async (): Promise<Organization> => {
  const sql = "select * from information_schema.organization";

  try {
    const result = await systemEngineEnvironment.execute<OrganizationRaw>(
      sql,
      {}
    );

    if (!result?.length || !result[0]?.rows?.[0]) {
      throw new ErrorWithTranslationKey("sso.fetch.error");
    }

    const response = result[0].rows[0];
    return {
      ...response,
      sso: JSON.parse(response.sso),
    };
  } catch (e) {
    throw new ErrorWithTranslationKey("sso.fetch.error");
  }
};
