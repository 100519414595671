import copy from "copy-to-clipboard";
import React from "react";

import { CopyIcon } from "components/Icons";

import styles from "./AccountFormModal.module.scss";

interface Props {
  title: string | null;
  text: string;
  infoToCopy: string;
}

export const InfoSection = (props: Props) => {
  const { title, text, infoToCopy } = props;
  return (
    <div className={styles.infoSection}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
      <div
        className={styles.copyIcon}
        onClick={() => {
          copy(infoToCopy);
        }}
      >
        <CopyIcon />
      </div>
    </div>
  );
};
