import { useQuery } from "react-query";

import { myAccountsQueryKey } from "./constants";
import { getMyAccounts } from "./getAccounts";

export const useAccounts = () => {
  const { data } = useQuery({
    queryKey: [myAccountsQueryKey],
    queryFn: () => getMyAccounts(),
    retryOnMount: false,
  });
  return data || [];
};
