import { useQuery } from "react-query";

import { OrganizationService } from "services/organization";

export const useOrganizationApproved = () => {
  const { data } = useQuery({
    queryKey: "isOrganizationApproved",
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: () => {
      return OrganizationService.isOrganizationApproved();
    },
  });
  return data;
};
