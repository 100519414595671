import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import createTheme from "./index";

import darkTheme from "./dark.module.scss";
import lightTheme from "./light.module.scss";

export const ThemeContext = createContext<{
  theme: string;
  toggle?: any;
}>({
  theme: localStorage.getItem("firebolt_theme") || "light",
  toggle: () => {},
});

type Props = {
  forcedTheme: string | undefined;
};

export const ThemeProvider = (props: React.PropsWithChildren<Props>) => {
  const { children, forcedTheme } = props;
  const [theme, toggle] = useState(
    forcedTheme || localStorage.getItem("firebolt_theme") || "light"
  );

  useEffect(() => {
    const darkClass = darkTheme.dark;
    const lightClass = lightTheme.light;

    // changing <body> class to not obstruct app wrappers and accomodate changes in body text color and scroll color
    document.body.classList.add(
      (forcedTheme || theme) === "dark" ? darkClass : lightClass
    );
    document.body.classList.remove(
      (forcedTheme || theme) === "dark" ? lightClass : darkClass
    );

    return () => {
      document.body.classList.remove(darkTheme.dark, lightTheme.light);
    };
  }, [theme, forcedTheme]);

  const themeMemo = React.useMemo(() => {
    return createTheme(theme);
  }, [theme]);

  const contextValue = useMemo(
    () => ({
      theme,
      toggle: (newTheme: string) => {
        toggle(newTheme);
      },
    }),
    [theme, toggle]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={themeMemo}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const LightThemeWrap = ({ children }: PropsWithChildren<{}>) => (
  <ThemeProvider forcedTheme="light">{children}</ThemeProvider>
);
