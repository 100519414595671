import { useQuery } from "react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import { authService } from "./index";

const ACCOUNT_ADMIN = "account_admin";

const getLoginRoleNames = async (loginName: string) => {
  const result = await systemEngineEnvironment.execute<{
    roleName: string;
  }>(
    `select distinct role_name from information_schema.applicable_roles where grantee = (
      select user_name from information_schema.users where LOWER(login_name) = LOWER(?)
    )`,
    { parameters: [loginName] }
  );
  const [response] = result;
  return response.rows;
};

export const useHasAccountAdminRole = () => {
  const { data } = useQuery({
    queryKey: ["accountAdminRole"],
    queryFn: async () => {
      const auth0user = await authService.auth0client.getUser();
      const loginName = auth0user?.email;
      const roles = await getLoginRoleNames(loginName as string);
      return roles.find(({ roleName }) => roleName === ACCOUNT_ADMIN);
    },
    suspense: true,
  });
  return !!data;
};
