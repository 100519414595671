import React from "react";

import styles from "./ContextMenu.module.scss";

const ContextMenuDivider = () => {
  return <div className={styles.divider} />;
};

ContextMenuDivider.role = "Divider";

export default ContextMenuDivider;
