import { MenuList } from "@mui/material";
import classNames from "classnames";
import React from "react";

import MultiSelectMenuItem from "components/Select/SelectMenuItem/MultiSelectMenuItem";

import { CustomMenu } from "./CustomMenu";
import { EmptyState } from "./EmptyState";
import { Search } from "./Search";
import { MIN_WIDTH, SearchOptions } from "./types";

import styles from "./Select.module.scss";

type Props = {
  childrenItems: React.ReactElement<{
    value: unknown;
    searchOptions: SearchOptions;
  }>[];
  value: unknown | unknown[];
  searchRef: React.MutableRefObject<HTMLDivElement | null>;
  selectRef: React.MutableRefObject<HTMLElement | null>;
  listRef: React.MutableRefObject<HTMLUListElement | null>;
  searchOptions?: SearchOptions;
  open: boolean;
  multiple?: boolean;
  searchQuery: string;
  handleChangeSearch: (query: string) => void;
  handleClose: () => void;
  onChange: (event: any, child?: React.ReactElement) => void;
  menuMinWidth: number;
  paperWidth?: "MD";
  itemsListTestId?: string;
};

const addElementToList = ({
  newValue,
  itemIndex,
  child,
}: {
  newValue: unknown[];
  itemIndex: number;
  child: React.ReactElement;
}) => {
  if (itemIndex === -1) {
    newValue.push(child.props.value);
  } else {
    newValue.splice(itemIndex, 1);
  }
};

export const MenuWithSearch: React.FC<Props> = props => {
  const {
    selectRef,
    listRef,
    handleClose,
    open,
    childrenItems,
    searchQuery,
    searchOptions,
    handleChangeSearch,
    onChange,
    multiple,
    value,
    searchRef,
    paperWidth,
    menuMinWidth,
    itemsListTestId,
  } = props;

  const filteredChildren =
    childrenItems.filter(item => {
      return item?.props?.searchOptions?.textForSearch
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
    }) || [];

  const handleItemClick =
    (child: React.ReactElement) => (event: React.MouseEvent) => {
      let newValue: unknown[];

      if (multiple) {
        newValue = Array.isArray(value) ? value.slice() : [];
        const itemIndex = (value as unknown[]).indexOf(child.props.value);
        addElementToList({
          newValue,
          itemIndex,
          child,
        });
      } else {
        newValue = child.props.value;
      }

      if (child.props.onClick) {
        child.props.onClick(event);
      }

      if (value === newValue) {
        return;
      }

      if (onChange) {
        event.persist();
        Object.defineProperty(event, "target", {
          writable: true,
          value: { value: newValue },
        });
        onChange(event, child);
      }
    };

  const items = filteredChildren.map(child => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const selected = multiple
      ? (value as unknown[]).some(v => v === child.props.value)
      : value === child.props.value;

    const props = {
      "aria-selected": selected ? "true" : undefined,
      onClick: handleItemClick(child),
      onKeyUp: (event: React.KeyboardEvent) => {
        if (event.key === " ") {
          event.preventDefault();
        }
      },
      role: "option",
      selected,
      value: undefined,
      "data-value": child.props.value,
    };

    return React.cloneElement(child, props);
  });

  const handleAllClick = (allSelected: boolean) => () => {
    if (allSelected) {
      onChange({
        target: {
          value: [],
        },
      });
    } else {
      onChange({
        target: {
          value: filteredChildren.map(child => child.props.value),
        },
      });
    }
  };

  const renderSelectAllElement = () => {
    if (multiple) {
      const allSelected = filteredChildren.every(child =>
        (value as unknown[]).includes(child.props.value)
      );
      return (
        <MultiSelectMenuItem
          key="all"
          value="all"
          selected={allSelected}
          className={styles.selectAllItem}
          disabled={!items.length}
          onClick={handleAllClick(allSelected)}
        >
          Show / Hide all ({filteredChildren.length})
        </MultiSelectMenuItem>
      );
    }
    return null;
  };

  const renderMenu = () => {
    const selectAllElement = renderSelectAllElement();

    const childrenElement = items.length ? (
      <MenuList ref={listRef}>{items}</MenuList>
    ) : (
      <EmptyState searchOptions={searchOptions} />
    );

    return (
      <div className={styles.searchMenu}>
        <Search
          key="search"
          handleChange={handleChangeSearch}
          searchOptions={searchOptions}
          ref={searchRef}
        >
          {selectAllElement}
        </Search>
        <div
          className={styles.childrenItems}
          data-testid={itemsListTestId}
        >
          {childrenElement}
        </div>
      </div>
    );
  };

  const customMenuProps = {
    PaperProps: {
      classes: {
        root: classNames(styles.paper, styles[`paperWidth${paperWidth}`]),
      },
      style: {
        width: Math.max(menuMinWidth, MIN_WIDTH),
        minWidth: menuMinWidth,
      },
    },
  };

  return (
    <CustomMenu
      anchorEl={selectRef.current}
      handleClose={handleClose}
      open={open}
      renderMenu={renderMenu}
      {...customMenuProps}
    />
  );
};
