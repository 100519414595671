import { useQuery } from "react-query";

import { getRoles } from "./getRoles";
import { Role } from "./types";

export const useRbacRoles = () => {
  const { data } = useQuery<Role[], Error>({
    queryKey: ["rbacRoles"],
    queryFn: () => {
      return getRoles();
    },
    suspense: true,
  });

  return data || [];
};
