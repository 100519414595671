import classNames from "classnames";
import React, { ReactElement } from "react";

import Spinner from "components/Spinner";
import Tooltip from "components/Tooltip";

import styles from "./Button.module.scss";

export enum ButtonTemplate {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Ghost = "ghost",
  Danger = "danger",
}

export enum ButtonSize {
  Medium = "medium",
  Small = "small",
  Tiny = "tiny",
}

export type Props = {
  text: React.ReactNode | string;
  className?: string;
  size?: ButtonSize;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  dataTestid?: string;
  id?: string;
  template?: ButtonTemplate;
  isLoading?: boolean;
  href?: string;
  tooltip?: {
    title: string;
  };
  disablePortal?: boolean;
  type?: string;
};

const Button = React.forwardRef((props: Props, ref) => {
  const {
    text,
    size = ButtonSize.Medium,
    startIcon,
    disabled = false,
    onClick,
    fullWidth = false,
    dataTestid,
    endIcon,
    id,
    template = ButtonTemplate.Primary,
    isLoading = false,
    className,
    href,
    tooltip,
    disablePortal = false,
    type,
  } = props;

  const buttonChild = (
    <>
      {startIcon && (
        <span className={styles.leftIconContainer}>{startIcon}</span>
      )}
      <span className={styles.text}>{text}</span>

      {endIcon && <span className={styles.rightIconContainer}>{endIcon}</span>}

      {isLoading && (
        <div className={styles.loadingContainer}>
          <Spinner size={size === ButtonSize.Small ? 14 : 18} />
        </div>
      )}
    </>
  );

  const buttonProps = {
    "data-testid": dataTestid,
    id,
    onClick: (event: React.SyntheticEvent) => {
      if (!disabled && !isLoading && onClick) {
        onClick && onClick(event);
      }
    },
    className: classNames(
      className,
      styles.root,
      styles[template],
      styles[size],
      {
        [styles.fullWidth]: fullWidth,
        [styles.loading]: isLoading,
        [styles.disabled]: disabled,
      }
    ),
    ref,
    type,
  };

  const wrapWithTooltip = (element: React.ReactElement) => {
    if (!tooltip) {
      return element;
    }

    return (
      <Tooltip
        title={tooltip.title}
        placement="top"
        PopperProps={{ disablePortal }}
      >
        {element}
      </Tooltip>
    );
  };

  const buttonComponent = href ? "a" : "button";

  href && Object.assign(buttonProps, { href, target: "_blank" });

  return wrapWithTooltip(
    React.createElement(buttonComponent, buttonProps, buttonChild)
  );
});

export default Button;
