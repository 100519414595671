import { useCallback, useState } from "react";

import { SupportAccessAgentType } from "services/api/supportAccess";

import { SupportAgentSteps, SupportAgentStepsEnum } from "./supportAgentSteps";

export const useSupportAgentAccess = () => {
  const [step, setStep] = useState<{
    type: SupportAgentStepsEnum;
    data?: any;
  } | null>(null);

  const openSupportAgentAccess = (
    onOpen: () => void,
    agent?: SupportAccessAgentType
  ) => {
    onOpen();
    if (agent) {
      setStep({ type: "menu" });
    } else {
      setStep({ type: "create" });
    }
  };

  const openSupportAgentStep = (step: SupportAgentStepsEnum) => {
    setStep({ type: step });
  };

  const setSuccessStep = () => {
    setStep(null);
  };

  const setEditStep = () => {
    setStep({ type: "edit" });
  };

  const closeSupportAgentAccess = useCallback(() => {
    setStep(null);
  }, []);

  let supportAgentAccessMarkup = null;

  if (step) {
    const Component = SupportAgentSteps[step.type];
    supportAgentAccessMarkup = (
      <Component
        onClose={closeSupportAgentAccess}
        onSuccess={setSuccessStep}
        setEditStep={setEditStep}
      />
    );
  }

  return {
    openSupportAgentAccess,
    closeSupportAgentAccess,
    supportAgentAccessMarkup,
    openSupportAgentStep,
  };
};
