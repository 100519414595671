import SnackBar from "components/SnackBar";

export const OfflineIndicator = () => {
  return (
    <SnackBar
      message="Not connected. Check your network."
      snackBarKey="offline"
      vertical="top"
      horizontal="center"
      openSnackBar={true}
      type="error"
      displayInline={true}
      dimmed={true}
    />
  );
};
