// Copied from https://github.com/manishsaraan/email-validator/blob/master/index.js
/* eslint-disable */
const tester =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
/* eslint-enable */

function isValidEmail(email: string) {
  if (!email) return false;

  const emailParts = email.split("@");

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  if (address.length > 255) return false;

  const domainParts = address.split(".");
  if (
    domainParts.some(part => {
      return part.length > 63;
    })
  )
    return false;

  if (!tester.test(email)) return false;

  return true;
}

function isPasswordValid(password: string, forbiddenWords: string[] = []) {
  const errors = {
    "validation.password.no_email_or_name": false,
    "validation.password.at_least_10": false,

    "validation.password.minimal_char_types": false,
    "validation.password.upper_case": false,
    "validation.password.lower_case": false,
    "validation.password.numbers": false,
    "validation.password.special": false,

    "validation.password.no_repeating": false,
  };
  // No email address or name
  if (
    !forbiddenWords
      .filter(w => w)
      .some(w => password.toLowerCase().includes(w.toLowerCase()))
  ) {
    errors["validation.password.no_email_or_name"] = true;
  }

  // At least 10 characters
  if (password.length >= 10) {
    errors["validation.password.at_least_10"] = true;
  }

  // Including at least 3 of the following 4 types of characters
  let charTypes = 0;
  if (/[a-z]/.test(password)) {
    errors["validation.password.lower_case"] = true;
    charTypes++; // lowercase
  }

  if (/[A-Z]/.test(password)) {
    errors["validation.password.upper_case"] = true;
    charTypes++; // uppercase
  }

  if (/\d/.test(password)) {
    errors["validation.password.numbers"] = true;
    charTypes++; // number
  }

  if (/[!@#$%^&*]/.test(password)) {
    errors["validation.password.special"] = true;
    charTypes++; // special characters
  }

  if (charTypes >= 3) {
    errors["validation.password.minimal_char_types"] = true;
    errors["validation.password.special"] = true;
    errors["validation.password.numbers"] = true;
    errors["validation.password.upper_case"] = true;
    errors["validation.password.lower_case"] = true;
  }

  // Not more than 2 identical characters in a row
  if (!/(\w)\1{2}/.test(password)) {
    errors["validation.password.no_repeating"] = true;
  }

  // If the password passes all rules, it is valid
  return errors;
}

function isValidName(name: string) {
  if (!name) {
    return false;
  }

  if (name.length > 64) {
    return false;
  }

  return true;
}

function isLettersAndNumbers(name: string) {
  const letters = /^[\p{L}',.-][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/gmu;
  return letters.test(name);
}

function isValidWorkEmail(email: string) {
  const emailRegex =
    /^([\w-.+]+@)(?!((me|mac|icloud|gmail|googlemail|live|msn|outlook|yahoo|aol|163|wp|sky|btinternet|hatmail|email|hotmail|gnail|protonmail|gmx|seznam|inbox|freemail|qq|citromail|abc|xyz|pqr|rediffmail|ymail|gamil|yopmail|yandex|mail|office|mailinator|tuta)\.))(([\w-]+\.)+[\w-]{2,100})?$/i;
  return emailRegex.test(email);
}

function isValidCompanyName(name: string) {
  return /^[a-zA-Z0-9-]+$/.test(name);
}

function isValidDatabaseName(database: string) {
  const nameRegex = /^[0-9a-zA-Z_]+$/;
  const invalidStartRegex = /^(([0-9]+)|(xml))/i;
  if (nameRegex.test(database) && !invalidStartRegex.test(database)) {
    return true;
  }
  return false;
}

function isValidEngineName(database: string) {
  const nameRegex = /^[0-9a-zA-Z_]{1,63}$/;
  return nameRegex.test(database);
}

function isDatabaseNameReserved(name: string) {
  if (name.toLowerCase() === "system") {
    return true;
  }
  return false;
}

function isValidNameStart(str: string): boolean {
  const invalidStartRegex = /^(([0-9_]+)|(xml))/i;
  return !invalidStartRegex.test(str);
}

function isValidUrl(str: string): boolean {
  const regex =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  return regex.test(str);
}

export const Validations = {
  isValidEmail,
  isPasswordValid,
  isValidName,
  isLettersAndNumbers,
  isValidWorkEmail,
  isValidDatabaseName,
  isValidEngineName,
  isValidNameStart,
  isDatabaseNameReserved,
  isValidUrl,
  isValidCompanyName,
};
