import { privateApi } from "services/api/private";

import { MyTenant } from "./types";

export const getMyTenants = async (): Promise<MyTenant[]> => {
  const response = await privateApi.get<{
    tenants: MyTenant[];
  }>("/myTenants");

  return response.data.tenants;
};
