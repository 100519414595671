import { Privilege } from "services/rbac/types";

import { rolesPrivilelges } from "pages/govern/privileges";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useAccessManager } from "components/App/accessManager";

const getGovernRoute = ({
  allowRoles,
  allowUsers,
}: {
  allowRoles: boolean;
  allowUsers: boolean;
}) => {
  if (allowUsers) {
    return "/users";
  }
  if (allowRoles) {
    return "/roles";
  }
  return "/";
};

export const useShowMenuItems = () => {
  const { isAllowedTo } = useAccessManager();
  const { getAccount } = useCurrentAccount();

  const privilegeMatch = (privilege: Privilege) => {
    if (privilege.type === "account") {
      const account = getAccount();
      return isAllowedTo(
        privilege.type,
        account?.accountName as string,
        privilege.action
      );
    }
    return isAllowedTo(privilege.type, privilege.resource, privilege.action);
  };

  const allowUsers = true;
  const allowRoles = rolesPrivilelges.every(privilegeMatch);

  return {
    govern: allowUsers || allowRoles,
    governRoute: getGovernRoute({ allowRoles, allowUsers }),
  };
};
