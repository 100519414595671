import { Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RESERVED_URL_SEARCH_PARAMS } from "utils/routes.constants";

import { useLoggedInUser } from "hooks/useLoggedInUser";
import { SqlRbacAction } from "services/rbac/action";

import ErrorBoundary from "components/ErrorBoundary";
import LoadingOverlap from "components/LoadingOverlap";
import { OnboardingInitiator } from "components/Onboarding/OnboardingInitiator";

import { Restricted } from "./Restricted";

type Privilege = { type: string; resource: string; action: SqlRbacAction };

const Protected = (props: {
  Component: React.ComponentType;
  privileges: Privilege[];
}) => {
  const { Component, privileges, ...rest } = props;
  const location = useLocation();

  const { isLoggedIn } = useLoggedInUser();

  if (isLoggedIn) {
    return (
      <OnboardingInitiator>
        <Restricted privileges={privileges}>
          <Component {...rest} />
        </Restricted>
      </OnboardingInitiator>
    );
  }

  const redirect = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(RESERVED_URL_SEARCH_PARAMS.REDIRECT, redirect);

  const to = `/login?${searchParams}`;

  return <Navigate to={to} />;
};

export const protectedRoute = (
  Component: React.ComponentType,
  privileges = []
) => {
  return (props: React.ComponentProps<typeof Component>) => (
    <ErrorBoundary>
      <Suspense fallback={<LoadingOverlap isLoading />}>
        <Protected
          Component={Component}
          privileges={privileges}
          {...props}
        />
      </Suspense>
    </ErrorBoundary>
  );
};
