import classNames from "classnames";
import React from "react";

import styles from "./ListView.module.scss";

export enum WrapOptions {
  wrap = "wrap",
  noWrap = "noWrap",
  wrapReverse = "wrapReverse",
}

type Props = {
  children: React.ReactNode;
  direction: "vertical" | "horizontal";
  spacingPx?: number;
  className?: string;
  childClassNames?: string;
  justifyContent?: boolean;
  alignCenter?: boolean;
  dataTestId?: string;
  equalElemWidth?: boolean;
  wrap?: WrapOptions;
};

const ListView = ({
  children,
  spacingPx,
  direction,
  justifyContent,
  alignCenter,
  className,
  childClassNames,
  dataTestId,
  equalElemWidth,
  wrap,
}: Props) => {
  const style: Record<string, unknown> = {};
  const firstElemStyle: Record<string, unknown> = {};

  if (direction === "vertical") {
    style.marginTop = `${spacingPx}px`;
  }

  if (direction === "horizontal") {
    style.marginLeft = `${spacingPx}px`;

    if (equalElemWidth) {
      const width = `calc( 100% / ${
        React.Children.toArray(children).length
      } - ${spacingPx}px)`;
      firstElemStyle.width = width;
      style.width = width;
    }
  }

  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        styles.root,
        direction && styles[direction],
        {
          [styles.justifyContent]: justifyContent,
          [styles.alignCenter]: alignCenter,
          [styles.wrap]: wrap === WrapOptions.wrap,
          [styles.noWrap]: wrap === WrapOptions.noWrap,
          [styles.wrapReverse]: wrap === WrapOptions.wrapReverse,
        },
        className
      )}
    >
      {React.Children.toArray(children)
        .filter(child => !!child)
        .map((child, index) => {
          return (
            <div
              // TODO: fix this
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames(styles.child, childClassNames)}
              style={index ? style : firstElemStyle}
            >
              {child}
            </div>
          );
        })}
    </div>
  );
};

export default ListView;
