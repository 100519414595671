import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ChevronRight } from "assets/icons/ChevronRight.svg";

import Button, { ButtonSize, ButtonTemplate } from "components/Button";

import { OnboardingDialog } from "../OnboardingDialog";
import { OnboardingStep } from "../types";
import { useOnboarding } from "../useOnboarding";
import { ReactComponent as LoadImage } from "./load.svg";

import styles from "./styles.module.scss";

export const LoadData = () => {
  const { setOnboardingStep } = useOnboarding();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContinue = () => {
    setTimeout(() => {
      navigate("/");
    });
    setOnboardingStep(OnboardingStep.Empty);
  };

  return (
    <OnboardingDialog
      image={<LoadImage />}
      title={<>{t("onboarding.load_data.title")}</>}
      body={<>{t("onboarding.load_data.description")}</>}
      actions={
        <div className={styles.actions}>
          <Button
            template={ButtonTemplate.Primary}
            size={ButtonSize.Medium}
            text={t("onboarding.load_data.load")}
            endIcon={<ChevronRight />}
            onClick={handleContinue}
            dataTestid="load-data"
          />

          <Button
            template={ButtonTemplate.Secondary}
            size={ButtonSize.Medium}
            text={t("onboarding.load_data.skip")}
            onClick={handleContinue}
            dataTestid="skip-load-data"
          />
        </div>
      }
    />
  );
};
