import { useQuery } from "react-query";

import { authService } from "services/auth";
import { getLoginByName } from "services/login/getLoginByName";

export const useLogin = () => {
  const { data } = useQuery({
    queryKey: ["login"],
    queryFn: async () => {
      const auth0user = await authService.auth0client.getUser();

      const loginName = auth0user?.email;
      if (!loginName) {
        throw new Error("login name not found");
      }

      const login = await getLoginByName(loginName);
      if (!login) {
        throw new Error("login not found");
      }
      return login;
    },
    suspense: true,
  });
  return data;
};
