import { ChannelTokens } from "components/topLevel/Websocket/websocket.types";

import { privateApi } from "../api/private";

const AUTH_TOKEN = "/notifications/getAuthToken";
const CHANNEL_TOKEN = "/notifications/getChannelTokens";

export const getWsAuthToken = async (): Promise<string> => {
  const response = await privateApi.get<any>(AUTH_TOKEN);
  return response.data.token;
};

export const getWSChannelTokens = async (
  channelNames: string[] // channel names like acc:<account_name>
): Promise<ChannelTokens> => {
  const response = await privateApi.post<{ tokens: ChannelTokens }>(
    CHANNEL_TOKEN,
    {
      channels: channelNames,
    }
  );
  return response.data.tokens;
};
