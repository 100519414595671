import { useTranslation } from "react-i18next";

import { ErrorScreen } from "../ErrorScreen";

const LinkingFailure = () => {
  const { t } = useTranslation();

  return <ErrorScreen title={t("aws.failure_title")} />;
};

export default LinkingFailure;
