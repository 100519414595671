import { QueryClient } from "react-query";

import { EventTypes } from "../websocket.types";
import { updateAccounts } from "./updateAccounts";
import { updateDatabases } from "./updateDatabases";
import { updateEngines } from "./updateEngines";
import { updateOrganization } from "./updateOrganization";

export const updateChannelEntity = async ({
  queryClient,
  data,
}: {
  queryClient: QueryClient;
  data: {
    type: EventTypes;
  };
}) => {
  switch (data.type) {
    case EventTypes.CreditsRunOut:
    case EventTypes.CreditExhausted:
    case EventTypes.PaymentRegistered:
      return await updateOrganization({ queryClient });
    case EventTypes.AccountCreated:
    case EventTypes.AccountUpdated:
    case EventTypes.AccountDeleted: {
      return await updateAccounts({ queryClient });
    }
    case EventTypes.DatabaseCreated:
    case EventTypes.DatabaseUpdated:
    case EventTypes.DatabaseDeleted:
      return await updateDatabases({ queryClient });
    case EventTypes.EngineCreated:
    case EventTypes.EngineUpdated:
    case EventTypes.EngineDeleted: {
      await updateDatabases({ queryClient });
      await updateEngines({ queryClient });
      break;
    }
    case EventTypes.LoginAccountsChanged: {
      await updateAccounts({ queryClient });
      break;
    }
    default:
  }
};
