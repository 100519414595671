import { useCallback, useMemo, useState } from "react";
import { Offline, PollingConfig } from "react-detect-offline";
import { Outlet, Route, Routes } from "react-router-dom";

import { useCreditsFlag } from "featureFlags/hooks/useCreditsFlag";
import { useDemoTool } from "featureFlags/hooks/useDemoTool";

import { AsyncError404 } from "pages/pages";

import { AccountProvider } from "components/Account/AccountProvider";
import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import ErrorBoundary from "components/ErrorBoundary";
import { MainSidebarGuest } from "components/MainSidebar/MainSidebarGuest";
import { MainSidebarUser } from "components/MainSidebar/MainSidebarUser";
import { MainSidebarWithoutAccounts } from "components/MainSidebar/MainSidebarWithoutAccounts";
import OfflineIndicator from "components/OfflineIndicator";
import withTracker from "components/common/GA";
import { WebsocketProvider } from "components/topLevel/Websocket/WebsocketProvider";

import { CreditsBanner } from "../Credits/CreditsBanner";
import { awsRoutes } from "./AwsRoutes";
import { generalRoutes } from "./GeneralRoutes";
import { guestRoutes } from "./GuestRoutes";
import {
  ProtectedAccountRoute,
  chooseAccountRoute,
  demoToolRoute,
  protectedRoutes,
} from "./ProtectedRoutes";

import styles from "../App/styles.module.scss";

const OFFLINE_POLLING_CONFIG: PollingConfig = {
  url: "/",
  enabled: true,
  interval: 1000 * 60,
  timeout: 5000,
};

const AuthorizedLayoutWrapper = () => {
  const currentAccount = useCurrentParamsAccount();
  const showCreditsBanner = useCreditsFlag();

  return (
    <AccountProvider key={currentAccount.accountName}>
      <WebsocketProvider>
        {showCreditsBanner && <CreditsBanner />}
        <div className={styles.app}>
          <MainSidebarUser />
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </WebsocketProvider>
      <Offline polling={OFFLINE_POLLING_CONFIG}>
        <OfflineIndicator />
      </Offline>
    </AccountProvider>
  );
};

const UnauthorizedLayoutWrapper = () => {
  return (
    <div className={styles.app}>
      <MainSidebarGuest />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

const renderErrorLayout = (errorElement: React.ReactElement) => {
  return (
    <div className={styles.app}>
      <MainSidebarWithoutAccounts />
      <div className={styles.content}>{errorElement}</div>
    </div>
  );
};

const Router: React.FC<{
  isLoggedIn?: boolean;
}> = props => {
  const [errorKey, setErrorKey] = useState(1);
  const showDemoTool = useDemoTool();

  const filteredProtectedRoutes = useMemo(() => {
    return showDemoTool
      ? [...protectedRoutes, ...demoToolRoute]
      : protectedRoutes;
  }, [showDemoTool]);

  const resetError = useCallback(() => {
    setErrorKey(key => key + 1);
  }, []);

  const routes = [...generalRoutes, ...guestRoutes];
  return (
    <Routes>
      <Route
        element={
          <ErrorBoundary
            layout={renderErrorLayout}
            resetError={resetError}
            key={errorKey}
          >
            {props.isLoggedIn ? (
              <AuthorizedLayoutWrapper />
            ) : (
              <UnauthorizedLayoutWrapper />
            )}
          </ErrorBoundary>
        }
      >
        <Route element={<ProtectedAccountRoute />}>
          {filteredProtectedRoutes}
        </Route>
        {awsRoutes}
        {chooseAccountRoute}
        {routes}
        <Route
          path="*"
          element={<AsyncError404 />}
        />
      </Route>
    </Routes>
  );
};

export default withTracker(Router);
