import { QueryClient } from "react-query";

export const updateEngines = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await queryClient.cancelQueries(["workspaceEngines"]);
    await queryClient.invalidateQueries(["workspaceEngines"]);
  } catch (e) {
    console.error("Failed to update engines from WS: ", e);
  }
};
