import { useEffect } from "react";

import { useIsOrganizationCreator } from "services/organization/useIsOrganizationCreator";
import { useOrganizationApproved } from "services/organization/useOrganizationApproved";

import { OnboardingStep } from "components/Onboarding/types";
import { useOnboarding } from "components/Onboarding/useOnboarding";

const FirstTimeOnboarding = (props: React.PropsWithChildren) => {
  const { children } = props;
  const { setOnboardingStep, step, skip } = useOnboarding();
  const isCreator = useIsOrganizationCreator();

  useEffect(() => {
    if (isCreator) {
      setOnboardingStep(OnboardingStep.Welcome);
    }
  }, [isCreator, setOnboardingStep]);

  if (step === OnboardingStep.Empty || skip) {
    return <>{children}</>;
  }
  return null;
};

export const OnboardingInitiator = (props: React.PropsWithChildren) => {
  const { children } = props;
  const approved = useOrganizationApproved();
  const { is_approved, welcome_completed } = approved || {};
  if (is_approved && !welcome_completed) {
    return <FirstTimeOnboarding>{children}</FirstTimeOnboarding>;
  }

  return <>{children}</>;
};
