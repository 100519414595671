import { useQuery } from "react-query";

import { OrganizationService, Region } from "services/organization";

export const useRegions = (): Region[] => {
  const { data } = useQuery({
    queryKey: ["regions"],
    queryFn: async () => {
      const data = await OrganizationService.availableRegions();
      return data.regions;
    },
  });
  return data || [];
};
