import { QueryClient } from "react-query";

export const updateOrganization = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  try {
    await Promise.all([
      queryClient.cancelQueries(["organization"]),
      queryClient.cancelQueries(["organizationTrialBalance"]),
    ]);
    await Promise.all([
      queryClient.invalidateQueries(["organization"]),
      queryClient.invalidateQueries(["organizationTrialBalance"]),
    ]);
  } catch (e) {
    console.error("Failed to update organization from WS: ", e);
  }
};
