import { createTheme } from "@mui/material";

import colors from "../styles/variables.colors.module.scss";

export default (theme: string) => {
  return createTheme({
    transitions: {
      // So we have `transition: none;` everywhere
      create: () => "none",
    },
    palette: {
      // @ts-ignore
      type: theme || "light",
      background: {
        paper: theme === "dark" ? "#242529" : "white",
      },
      primary: {
        main: colors.red,
        dark: colors.redDark,
      },
      secondary: {
        main: colors.black2,
        dark: colors.black5,
      },
      text: {
        primary: "var(--grey_2)",
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            maxWidth: "420px",
            border: "none",
            borderBottom: "1px solid #a4a4a4",
            boxSizing: "border-box",
            fontSize: "15px",
            fontFamily: "Inter",
            "&::before, &::after": {
              borderBottom: "none !important",
            },
            "& .Mui-error": {
              color: "#5A5859 !important",
              borderBottom: "1px solid #E72D2D",
            },
          },
          input: {
            marginTop: "4px",
            paddingBottom: "3px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            fontSize: "13px",
            transform: "translate(0, 1.5px) scale(1)",
            lineHeight: "1.4",
          },
          root: {
            fontSize: "15px",
            fontFamily: "Inter",
            color: "var(--grey_5)",
            "&.Mui-focused": {
              color: "var(--grey_5)",
              fontSize: "13px",
              transform: "translate(0, 1.5px) scale(1)",
              lineHeight: "1.4",
            },
            "&.Mui-shrink": {
              color: "var(--grey_5)",
              fontSize: "13px",
              transform: "translate(0, 1.5px) scale(1)",
              lineHeight: "1.4",
            },
            "&.Mui-error": {
              color: "#ADACAD !important",
            },
            "&.Mui-disabled": {
              color: "var(--grey_5)",
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            top: "20px !important",
            width: "100%",
            right: "auto",
            left: "auto !important",
            transform: "unset !important",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: "0px",
          },
          padding: {
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            padding: 0,
            color: colors.black2,
          },
          primary: {
            fontFamily: "Inter",
            fontSize: "13px",
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: {
            color: colors.black9,
          },
        },
      },
    },
  });
};
