import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "assets/icons/Logo.svg";

import { AQA_LINK_HEADER } from "./AqaIds";
import HelpBar from "./HelpBar/HelpBar";

import styles from "./MainSidebar.module.scss";

export const MainSidebarGuest = () => {
  return (
    <div
      className={styles.root}
      data-testid="sidebar-guest"
    >
      <div className={styles.sidebarHead}>
        <Link
          key="app_header"
          to="/"
          data-testid={AQA_LINK_HEADER}
          className={styles.logo}
        >
          <Logo />
        </Link>
      </div>
      <div className={styles.sidebarFooter}>
        <HelpBar />
      </div>
    </div>
  );
};
