import React from "react";

import { StatusMessageQueueContext } from "components/StatusMessageQueue/StatusMessageQueueProvider";

const useStatusMessageQueue = () => {
  const context = React.useContext(StatusMessageQueueContext);
  if (context === undefined) {
    throw new Error(
      "useStatusMessageQueue must be used within a StatusMessageQueueProvider"
    );
  }

  const { putStatusMessage, removeStatusMessage, resetStatusMessages } =
    context;

  return { putStatusMessage, removeStatusMessage, resetStatusMessages };
};

export default useStatusMessageQueue;
