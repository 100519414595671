import classNames from "classnames";
import { ReactNode } from "react";

import { QuestionIconV2 } from "components/Icons";
import CustomTooltip from "components/Tooltip";

import styles from "./FormRow.module.scss";

type Props = {
  required?: boolean;
  label: string;
  input: ReactNode;
  helperText?: string;
  classes?: {
    row?: string;
    label?: string;
    inputContainer?: string;
  };
};

const FormRow = (props: Props) => {
  const { input, label, required, classes, helperText } = props;
  return (
    <div className={classNames(styles.row, classes?.row)}>
      <div
        className={classNames(styles.label, classes?.label, {
          [styles.required]: required,
        })}
      >
        <span>{label}</span>
        {!!helperText && (
          <CustomTooltip
            title={helperText}
            placement="top"
          >
            <div className={styles.labelIcon}>
              <QuestionIconV2 />
            </div>
          </CustomTooltip>
        )}
      </div>
      <div
        className={classNames(styles.inputContainer, classes?.inputContainer)}
      >
        {input}
      </div>
    </div>
  );
};

export default FormRow;
