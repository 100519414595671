import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";
import { NoAccess } from "components/Icons";

import styles from "./styles.module.scss";

export const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <ErrorScreen
      title="Access Denied"
      image={<NoAccess />}
      imagePosition={ErrorScreenImagePosition.Top}
      classes={{
        image: classNames(styles.image, styles.forced, styles.forced2),
        description: styles.description,
      }}
      description={t("errors.unauthorized") as string}
    />
  );
};
