import { useQuery } from "react-query";

import { MyAccount } from "services/account/account.types";

export const useInitializeAccount = ({
  initializeAccount,
  currentAccount,
}: {
  initializeAccount: (account: MyAccount) => Promise<void>;
  currentAccount: MyAccount;
}) => {
  useQuery({
    queryKey: "initializeAccount",
    queryFn: () => {
      return initializeAccount(currentAccount);
    },
    suspense: true,
    cacheTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });
};
