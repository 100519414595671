import { FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import CustomSelect from "components/Select/Select";
import SelectMenuItem from "components/Select/SelectMenuItem/SelectMenuItem";

import {
  ContactFormField,
  DisabledFields,
  Fields,
  SEVERITY_OPTIONS,
  TEST_IDS,
} from "../constants";
import { Translations } from "../translations";

type Props = {
  handleChange: (name: string, value: string) => void;
  autoFocus?: boolean;
  fields: Fields;
  disabledFields: DisabledFields;
};

const Severity = (props: Props) => {
  const { fields, disabledFields, handleChange, autoFocus } = props;

  const { t } = useTranslation();
  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      handleChange(ContactFormField.Severity, event.target.value as string);
    },
    [handleChange]
  );

  const items = [
    ...SEVERITY_OPTIONS.map(item => (
      <SelectMenuItem
        key={`${item.value}`}
        value={`${item.value}`}
        testId={`${TEST_IDS.severityValue}${item.value}`}
      >
        {t(item.text)}
      </SelectMenuItem>
    )),
  ];

  return (
    <FormControl fullWidth>
      <InputLabel>{t(Translations[ContactFormField.Severity])}</InputLabel>
      <CustomSelect
        testId={TEST_IDS.severitySelect}
        fullWidth
        value={fields[ContactFormField.Severity]}
        disabled={disabledFields[ContactFormField.Severity]}
        onChange={handleSelectChange}
        autoFocus={autoFocus}
      >
        {items}
      </CustomSelect>
    </FormControl>
  );
};

export default Severity;
