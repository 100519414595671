import { SqlRbacAction } from "services/rbac/action";

export const usersPrivileges = [
  {
    type: "account",
    resource: "*",
    action: SqlRbacAction.USER_CREATE,
  },
  {
    type: "account",
    resource: "*",
    action: SqlRbacAction.USER_MODIFY_ANY,
  },
];

export const rolesPrivilelges = [
  {
    type: "account",
    resource: "*",
    action: SqlRbacAction.ROLE_CREATE,
  },
  {
    type: "account",
    resource: "*",
    action: SqlRbacAction.ROLE_MODIFY_ANY,
  },
  {
    type: "account",
    resource: "*",
    action: SqlRbacAction.GRANTS_MANAGE,
  },
];
