import { useTranslation } from "react-i18next";

import { RBAC_NAME_TYPE_MAP, SystemRole } from "services/rbac/constants";
import { useRbacRoles } from "services/rbac/useRbacRoles";

import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import OutlinedSelect from "components/OutlinedSelect";

import formRowStyles from "../FormRow/FormRow.module.scss";

type Props = {
  initialRoles?: string[];
  value: string[];
  onChange: (val: string[]) => void;
};

const RoleSelect = (props: Props) => {
  const { initialRoles, onChange, value } = props;
  const { t } = useTranslation();

  const roles = useRbacRoles();

  const sortedRoles = [...roles].sort((a, b) =>
    a.roleName.toLocaleLowerCase().localeCompare(b.roleName.toLocaleLowerCase())
  );

  const systemRoles = sortedRoles.filter(role => {
    const systemDefined = RBAC_NAME_TYPE_MAP[role.roleName as SystemRole];
    return systemDefined;
  });

  const regularRoles = sortedRoles.filter(role => {
    const systemDefined = RBAC_NAME_TYPE_MAP[role.roleName as SystemRole];
    return !systemDefined;
  });

  const selectItems = [
    ...systemRoles.map(role => {
      const { roleName } = role;
      return (
        <ContextMenuItem
          value={roleName}
          key={roleName}
          checked={value.includes(roleName)}
          checkedIconPlaceholder={true}
          text={roleName}
          testId={`role-item-${roleName}`}
          direction="left"
        />
      );
    }),
    <ContextMenuDivider key="divider" />,
    ...regularRoles.map(role => {
      const { roleName } = role;
      return (
        <ContextMenuItem
          value={roleName}
          key={roleName}
          testId={`role-item-${roleName}`}
          checked={value.includes(roleName)}
          checkedIconPlaceholder={true}
          text={roleName}
          direction="left"
        />
      );
    }),
  ];
  return (
    <OutlinedSelect
      multiple
      className={formRowStyles.selectInput}
      initialSelected={initialRoles}
      onSelect={(items: string[]) => {
        onChange(items);
      }}
      testId="roles-select"
      allowUncheck
      searchOptions={{
        searchPlaceholder: t("workspace.create_user.search_roles"),
        noResultsText: t("workspace.create_user.no_roles_found"),
      }}
    >
      {selectItems}
    </OutlinedSelect>
  );
};

export default RoleSelect;
