import { useQuery } from "react-query";

import { OrganizationService } from "services/organization";

import { organizationQueryKey } from "pages/configure/SSO/constants";

import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useOrganization = () => {
  const { putStatusMessage } = useStatusMessageQueue();
  const { data, isLoading } = useQuery({
    queryKey: [organizationQueryKey],
    queryFn: () => {
      return OrganizationService.getOrganization();
    },
    onError: (err: Error) => {
      putStatusMessage({
        message: err?.message,
        type: StatusMessageType.Error,
      });
    },
  });

  return { data, isLoading };
};
