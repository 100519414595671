import { Centrifuge } from "centrifuge";

import { getWsAuthToken } from "services/ws/getWsAuthToken";

const REACT_APP_API_SUB_DOMAIN = process.env.REACT_APP_API_SUB_DOMAIN;
export const initializeWSClient = async () => {
  const authToken = await getWsAuthToken();
  const centrifuge = new Centrifuge(
    `wss://api.us-east-1.${REACT_APP_API_SUB_DOMAIN}.firebolt.io/connection/websocket`,
    {
      token: authToken,
      getToken: () => getWsAuthToken(),
    }
  );

  centrifuge.connect();
  return centrifuge;
};
