import classNames from "classnames";

import styles from "./TooltipItem.module.scss";

export interface TooltipItemProps {
  name: string;
  value: string;
  noMargin?: boolean;
}

const TooltipItem = (props: TooltipItemProps) => {
  const { name, value, noMargin } = props;
  return (
    <div
      className={classNames(styles.itemWrap, { [styles.noMargin]: noMargin })}
    >
      {name && <span className={styles.itemLabel}>{name} </span>}
      <span className={styles.itemValue}>{value}</span>
    </div>
  );
};

export default TooltipItem;
