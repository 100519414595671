import { SnackbarContent } from "@mui/material";
import cn from "classnames";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ErrorIcon, SuccessIcon } from "components/Icons";
import snackbarStyles from "components/SnackBar/styles.module.scss";
import Spinner from "components/Spinner";
import {
  StatusMessage,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import styles from "components/StatusMessageQueue/StatusMessageQueueView/StatusMessageQueueView.module.scss";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

interface Props {
  messages: StatusMessage[];
}

const StatusMessageIcon = ({ type }: { type: string }) => {
  switch (type) {
    case StatusMessageType.Error:
      return <ErrorIcon className={snackbarStyles.messageIcon} />;
    case StatusMessageType.Success:
      return <SuccessIcon className={snackbarStyles.messageIcon} />;
    case StatusMessageType.Loading:
      return <Spinner size={22} />;
    default:
      return null;
  }
};

const StatusMessageQueueView = (props: Props) => {
  const { messages } = props;

  const { resetStatusMessages } = useStatusMessageQueue();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        resetStatusMessages();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, resetStatusMessages]);
  if (!messages.length) return null;

  return (
    <div
      ref={ref}
      data-testid="status-message-queue"
      className={cn(styles.root, {
        [styles.hidden]: !messages.length,
        [styles.transparentClick]:
          messages.length === 1 &&
          messages[0].type === StatusMessageType.Loading,
      })}
    >
      {messages.map(statusMessage => {
        const { message, type } = statusMessage;
        return (
          <div
            className={styles.snackbarContainer}
            key={JSON.stringify(message) + type}
            data-testid={`status-message-${type}`}
          >
            <SnackbarContent
              classes={{
                root: cn(snackbarStyles.snackBarContent, {
                  [snackbarStyles.errorSnackBar]: type === "error",
                  [snackbarStyles.successSnackBar]: type === "success",
                  [snackbarStyles.loadingSnackBar]: type === "loading",
                }),
              }}
              data-testid={statusMessage?.options?.testId}
              message={
                <div className={snackbarStyles.messageWrap}>
                  <StatusMessageIcon type={type} />
                  <span className={snackbarStyles.messageText}>
                    {typeof message === "string" ? t(message as any) : message}
                  </span>
                </div>
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default StatusMessageQueueView;
