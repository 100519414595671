import React from "react";

import { OnboardingStep, onboardingOrder } from "./types";

type OnboardingContextType = {
  nextStep: () => void;
  setOnboardingStep: (step: OnboardingStep) => void;
  step: OnboardingStep;
  onboardingOrder: OnboardingStep[];
  skip: boolean;
};

export const OnboardingContext = React.createContext<OnboardingContextType>({
  nextStep: () => {},
  setOnboardingStep: (_step: OnboardingStep) => {},
  step: OnboardingStep.Empty,
  onboardingOrder,
  skip: false,
});
