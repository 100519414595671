import { FormControl, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Account } from "services/account/account.types";
import { MyTenant } from "services/costs/types";
import { Region } from "services/organization";

import MaterialButton, { ButtonTemplate } from "components/Button";
import FormModal from "components/FormModal/FormModal";
import InputField from "components/Input/InputField";
import ListView from "components/ListView/ListView";
import CustomSelect from "components/Select/Select";
import SelectMenuItem from "components/Select/SelectMenuItem/SelectMenuItem";

import { useAccountActions } from "../../hooks/useAccountActions";
import { InfoSection } from "./InfoSection";

import styles from "./AccountFormModal.module.scss";

interface Props {
  availableRegions: Region[];
  onClose: () => void;
  onCreate?: (account: { accountName: string }) => void;

  /*
   * If account is {}, the form will be in "create" mode.
   * Otherwise, it will be in "edit" mode.
   */
  account: Account | {};
  tenants: MyTenant[];
}

const AccountFormModal = (props: Props) => {
  const { t } = useTranslation();
  const { onClose, onCreate, availableRegions, account, tenants } = props;

  const tenant = tenants.find(
    t => t.account_id === (account as Account).accountId
  );

  const {
    editMode,
    loading,
    createMutation,
    fieldsErrors,
    formFields,
    setFormFields,
    updateMutation,
    validateForm,
    isStateDirty,
    formWasEdited,
    setFormWasEdited,
  } = useAccountActions({
    regions: availableRegions,
    account,
    onClose,
    onCreate,
  });

  const isFormValid =
    !fieldsErrors.name && !fieldsErrors.region && !!formFields.name;

  const renderAccountInfo = (account: Account) => {
    return (
      <ListView
        spacingPx={24}
        direction="vertical"
        className={styles.info}
      >
        <InfoSection
          title={t("accounts.form.account_id")}
          text={account.accountId}
          infoToCopy={account.accountId}
        />

        <InfoSection
          title={t("accounts.form.account_url")}
          text={account.url}
          infoToCopy={account.url}
        />

        {!!tenant?.tenant_id && (
          <InfoSection
            title={t("accounts.form.aws_account_id")}
            text={tenant.tenant_id}
            infoToCopy={tenant.tenant_id}
          />
        )}
      </ListView>
    );
  };

  const handleInputChange = (name: string, value: string) => {
    setFormFields({ ...formFields, [name]: value });

    if (!formWasEdited) {
      setFormWasEdited(true);
    }
  };

  const handleFormSubmit = () => {
    if (!isStateDirty() || loading) {
      return;
    }

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (editMode) {
      updateMutation.mutate();
    } else {
      createMutation.mutate();
    }
  };

  return (
    <FormModal
      maxWidth={false}
      title={
        (editMode
          ? t("accounts.form.edit_account")
          : t("accounts.form.create_account")) as string
      }
      bodyContent={
        <form
          className={styles.root}
          onSubmit={e => {
            e.preventDefault();
            handleFormSubmit();
          }}
        >
          <ListView
            spacingPx={24}
            direction="horizontal"
            justifyContent={true}
            childClassNames={styles.formControl}
          >
            <FormControl
              fullWidth={true}
              className={styles.name}
            >
              <InputField
                fullWidth={true}
                label={t("accounts.form.account_name")}
                type="text"
                name="name"
                disabled={loading}
                testId="account-name-field"
                value={formFields.name}
                onChange={handleInputChange}
                autoFocus={true}
                errorText={fieldsErrors.name}
              />
            </FormControl>

            <FormControl fullWidth={true}>
              <InputLabel className={styles.formLabel}>
                {t("accounts.form.region")}
              </InputLabel>
              <CustomSelect
                label={t("accounts.form.region")}
                name="region"
                testId="region-select"
                fullWidth={true}
                disabled={editMode || loading}
                value={formFields.region}
                onChange={({ target: { value } }) =>
                  handleInputChange("region", value as string)
                }
              >
                {availableRegions.map(region => (
                  <SelectMenuItem
                    key={region.name}
                    value={region.name}
                    testId={`region-${region.name}`}
                  >
                    {region.displayName}
                  </SelectMenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </ListView>

          {editMode && renderAccountInfo(account as Account)}
          <button hidden={true}>submit</button>
        </form>
      }
      footerContent={
        <ListView
          spacingPx={16}
          direction="horizontal"
        >
          {editMode ? (
            <MaterialButton
              disabled={!isFormValid || !isStateDirty() || loading}
              text={t("accounts.form.update.submit")}
              isLoading={loading}
              dataTestid="update-account-submit"
              onClick={handleFormSubmit}
            />
          ) : (
            <MaterialButton
              disabled={!isFormValid || loading}
              isLoading={loading}
              dataTestid="create-account-submit"
              text={t("accounts.form.create.submit")}
              onClick={handleFormSubmit}
            />
          )}
          {!loading && (
            <MaterialButton
              text={t("accounts.form.cancel")}
              template={ButtonTemplate.Ghost}
              onClick={onClose}
            />
          )}
        </ListView>
      }
      open={true}
      onClose={onClose}
    />
  );
};

export default AccountFormModal;
