import { FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAccounts } from "services/account/useAccounts";

import CustomSelect from "components/Select/Select";
import SelectMenuItem from "components/Select/SelectMenuItem/SelectMenuItem";

import {
  ContactFormField,
  DisabledFields,
  Fields,
  TEST_IDS,
} from "../constants";
import { Translations } from "../translations";

type Props = {
  handleChange: (name: string, value: string) => void;
  autoFocus?: boolean;
  fields: Fields;
  disabledFields: DisabledFields;
};

const Severity = (props: Props) => {
  const { fields, disabledFields, handleChange, autoFocus } = props;

  const { t } = useTranslation();
  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      handleChange(ContactFormField.Account, event.target.value as string);
    },
    [handleChange]
  );

  const accounts = useAccounts();
  const items =
    accounts && accounts.length
      ? accounts.map(item => (
          <SelectMenuItem
            key={`${item.accountName}`}
            value={`${item.accountName}`}
            testId={`${TEST_IDS.accountInput}${item.accountName}`}
          >
            {t(item.accountName)}
          </SelectMenuItem>
        ))
      : [];

  return (
    <FormControl fullWidth>
      <InputLabel>{t(Translations[ContactFormField.Account])}</InputLabel>
      <CustomSelect
        testId={TEST_IDS.accountInput}
        fullWidth
        value={fields[ContactFormField.Account]}
        disabled={disabledFields[ContactFormField.Account] || !items.length}
        onChange={handleSelectChange}
        autoFocus={autoFocus}
      >
        {items}
      </CustomSelect>
    </FormControl>
  );
};

export default Severity;
