import { useState } from "react";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";

import ErrorBoundary from "components/ErrorBoundary";
import { GatedOrganization } from "components/GatedOrganization/GatedOrganization";
import { MainSidebarWithoutAccounts } from "components/MainSidebar/MainSidebarWithoutAccounts";
import { OnboardingV2 } from "components/Onboarding/OnboardingV2";
import Router from "components/Router";

import { OrganizationRedirect } from "./OrganizationRedirect";

import styles from "./styles.module.scss";

const layout = (errorElement: React.ReactElement) => {
  return (
    <div className={styles.app}>
      <MainSidebarWithoutAccounts />
      <div className={styles.content}>{errorElement}</div>
    </div>
  );
};

export const LoggedLayout = () => {
  const [errorKey, setErrorKey] = useState(1);
  const flags = useAllFlags();

  const isIngestionWizardEnabled =
    flags[FeatureFlag.FireboltAppEnableIngestionWizard];
  const resetError = () => {
    setErrorKey(key => key + 1);
  };

  return (
    <div className={styles.layout}>
      <ErrorBoundary
        layout={layout}
        key={errorKey}
        resetError={resetError}
      >
        <OrganizationRedirect>
          <GatedOrganization>
            <Router isLoggedIn />
            {isIngestionWizardEnabled && <OnboardingV2 />}
          </GatedOrganization>
        </OrganizationRedirect>
      </ErrorBoundary>
    </div>
  );
};
