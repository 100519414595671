import { useEffect } from "react";

const ID = "gtm-script-loader";

export const withGoogleTagManager = (Component: React.ComponentType) => {
  const WithTag: React.ComponentType = props => {
    useEffect(() => {
      if (
        process.env.REACT_APP_HUBSPOT_TRACK_CODE &&
        !document.getElementById(ID)
      ) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GTM_CODE}`;
        script.id = ID;
        script.async = true;
        document.body.appendChild(script);
      }
      return () => {
        const script = document.getElementById(ID);
        script && script.remove();
      };
    }, []);
    return <Component {...props} />;
  };
  return WithTag;
};
