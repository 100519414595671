import { ContactFormField } from "./constants";

export const Translations = {
  [ContactFormField.FirstName]: "contact.firstName",
  [ContactFormField.LastName]: "contact.lastName",
  [ContactFormField.Account]: "contact.account",
  [ContactFormField.Organization]: "contact.organization",
  [ContactFormField.Email]: "contact.email",
  [ContactFormField.EngineName]: "contact.engineName",
  [ContactFormField.Subject]: "contact.subject",
  [ContactFormField.Description]: "contact.description",
  Description: "contact.description",
  [ContactFormField.Severity]: "contact.severity",
  [ContactFormField.Name]: "contact.name",
  descriptionPlaceholder: "contact.descriptionPlaceholder",
  successSubmitMessage1: "contact.successSubmitMessage1",
  successSubmitMessage2: "contact.successSubmitMessage2",
  errorSubmitMessage: "contact.errorSubmitMessage",
  errors: "contact.errors.",
  pageTitle: "contact.title",
  helpTooltipTitle: "helpTooltipTitle",
  STRING_TOO_LONG: "contact.backendStringTooLong",
  INVALID_EMAIL_ADDRESS: "contact.backendInvalidEmailAddress",
  submit: "contact.submit",
  cancel: "contact.cancel",
};
