import classNames from "classnames";
import React from "react";

import Spinner from "components/Spinner";

import styles from "./styles.module.scss";

interface Props {
  dimmed?: boolean;
  isLoading: boolean;
  className?: string;
  testId?: string;
  renderContent?: (params: { defaultSpinner: JSX.Element | null }) => any;
}

const LoadingOverlap: React.FC<Props> = props => {
  const {
    dimmed = false,
    isLoading,
    className,
    renderContent,
    testId = "loading-overlap",
  } = props;

  const spinner = isLoading ? <Spinner size={28} /> : null;
  return (
    <div
      data-testid={testId}
      className={classNames(className, styles.overlayWrap, {
        [styles.dimmed]: dimmed,
        [styles.isLoading]: isLoading,
      })}
    >
      {renderContent && renderContent({ defaultSpinner: spinner })}
      {!renderContent && spinner}
    </div>
  );
};

export default LoadingOverlap;
