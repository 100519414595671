import { Centrifuge } from "centrifuge";
import React from "react";

import { ChannelTokens } from "./websocket.types";

export const WebsocketContext = React.createContext<{
  getClient: () => Centrifuge | undefined;
  getChannelTokens: () => ChannelTokens;
}>({
  getClient: () => undefined,
  getChannelTokens: () => ({}),
});
