import { generatePath } from "react-router-dom";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

export const useFormatPath = () => {
  const { getAccount } = useCurrentAccount();
  const formatPath = (to: string) => {
    const account = getAccount();
    const params = {
      accountName: account?.accountName ?? "",
    };
    const path = generatePath(to, params);
    return path;
  };
  return formatPath;
};
