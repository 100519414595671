import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ID = "hs-script-loader";

export const withHubspot = (Component: React.ComponentType) => {
  const Hubspoted: React.FC = props => {
    const { pathname } = useLocation();

    useEffect(() => {
      if (
        process.env.REACT_APP_HUBSPOT_TRACK_CODE &&
        !document.getElementById(ID)
      ) {
        (window as any)._hsq = (window as any)._hsq || [];
        (window as any)._hsq.push(["setPath", pathname]);

        const script = document.createElement("script");
        script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACK_CODE}.js`;
        script.id = ID;
        script.async = true;
        document.body.appendChild(script);
      }
      return () => {
        const script = document.getElementById(ID);
        script && script.remove();
      };
    }, [pathname]);
    return <Component {...props} />;
  };

  return Hubspoted;
};
