export enum RbacActionObject {
  DATABASE = "DATABASE",
  ENGINE = "ENGINE",
}

export enum SqlRbacAction {
  ENGINE_USAGE = "USAGE",
  ENGINE_MODIFY = "MODIFY",
  ENGINE_OPERATE = "OPERATE",

  DATABASE_USAGE = "USAGE",
  DATABASE_MODIFY = "MODIFY",

  ENGINE_CREATE = "CREATE ENGINE",
  DATABASE_CREATE = "CREATE DATABASE",

  ENGINE_USAGE_ANY = "USAGE ANY ENGINE",
  ENGINE_MODIFY_ANY = "MODIFY ANY ENGINE",
  ENGINE_OPERATE_ANY = "OPERATE ANY ENGINE",

  DATABASE_USAGE_ANY = "USAGE ANY DATABASE",
  DATABASE_MODIFY_ANY = "MODIFY ANY DATABASE",

  USER_MODIFY = "MODIFY",

  USER_MODIFY_ANY = "MODIFY ANY USER",

  USER_CREATE = "CREATE USER",

  USER_CREATE_ANY = "CREATE ANY USER",
  USER_CONNECT_ANY = "CONNECT ANY USER",

  ROLE_MODIFY = "MODIFY",
  ROLE_CREATE = "CREATE ROLE",

  ROLE_MODIFY_ANY = "MODIFY ANY ROLE",
  ROLE_MANAGE_ANY = "MANAGE ANY ROLE",

  GRANTS_MANAGE = "MANAGE GRANTS",
}
