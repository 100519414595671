export const saveLatestAccount = (accountName: string) => {
  try {
    localStorage.setItem("accountName", accountName);
  } catch (error) {
    console.log(error);
  }
};

export const getLatestAccount = () => {
  try {
    return localStorage.getItem("accountName");
  } catch (error) {
    return undefined;
  }
};
