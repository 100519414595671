import { KeyboardEvent } from "react";

// click target element on space or enter press
export const clickOnSpaceOrEnterPress = (event: KeyboardEvent) => {
  const { key, target } = event;
  const { tagName } = target as HTMLElement;

  if (key === " " || key === "Enter") {
    if (tagName === "A") {
      event.preventDefault();
    }

    (target as HTMLElement).click();
  }
};
