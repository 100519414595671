export const ROUTES = {
  ROOT: "/:accountName?",
  LOGOUT: "/:accountName/logout",
  DEVELOP: "/:accountName/develop",
  CHOOSE_ACCOUNT: "/:accountName/choose-account",
  ACCOUNTS: "/:accountName/accounts",
  CONFIGURE: "/:accountName/configure",
  GOVERN: "/:accountName/govern",
  DEMO_TOOL: "/:accountName/internal-demo-tool",
};

export const GUEST_ROUTES = {
  LOGIN_HANDLE: "/login/handle",
  LOGIN_ERROR: "/login/error",
  LOGIN: "/login",
  CREATE_ACCOUNT: "/create-account",
  CREATE_ORGANIZATION: "/signup",
  FIND_ORGANIZATION: "/find-organization",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_TOKEN: "/password/reset/:tokenId",
  ACCEPT_INVITATION: "/accept-account-invitation/:accountId/:token",
};

export const GENERAL_ROUTES = {
  NO_ACCESS: "/access-error/no-access",
  LOGOUT: "/logout",
};

export const RESERVED_URL_SEARCH_PARAMS = {
  REDIRECT: "redirect",
};

export const AWS_ROUTES = {
  GETTING_STARTED: "/getting-started",
  LINK_FAILURE: "/aws-marketplace-linking-failure",
  LINK_ALREADY_LINKED: "/aws-marketplace-linking-already-linked",
};
