import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

type Props = {
  children?: React.ReactNode;
};

export function withTitle<T>(
  key: string,
  Component: (props?: any) => React.ReactElement | JSX.Element | null
) {
  return (props: T & Props) => {
    const { t } = useTranslation();
    return (
      <>
        <Helmet>
          <title>{t(key)}</title>
        </Helmet>
        <Component {...props} />
      </>
    );
  };
}

withTitle.displayName = "ComponentWithTitle";
