import { useQuery } from "react-query";

import { OrganizationService } from "services/organization";

export const useIsOrganizationCreator = () => {
  const { data } = useQuery({
    queryKey: ["isOrganizationCreator"],
    queryFn: () => {
      return OrganizationService.isOrganizationCreator();
    },
  });
  return data;
};
