import React from "react";

import styles from "./styles.module.scss";

interface Props {
  message: string;
  link: string;
  href: string;
}

const SnackLinkMessage = (props: Props) => {
  const { message, link, href } = props;

  return (
    <span className={styles.messageWrap}>
      <span data-testid="link-container">
        {message}{" "}
        <a
          href={href}
          data-testid="link-href"
        >
          {link}
        </a>
      </span>
    </span>
  );
};

export default SnackLinkMessage;
