import classNames from "classnames";
import React from "react";

import { ReactComponent as CheckIcon } from "assets/icons/CheckIcon.svg";

import Checkbox from "components/Checkbox";

import commonStyles from "styles/common.module.scss";
import styles from "./ContextMenu.module.scss";

export interface ContextMenuItemProps {
  // eslint-disable-next-line react/no-unused-prop-types
  value?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  disableClose?: boolean;
  text: string;
  onClick?: (
    event: React.SyntheticEvent<HTMLDivElement | HTMLAnchorElement>
  ) => void;
  secondaryText?: string;
  content?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  checkedIconPlaceholder?: boolean;
  active?: boolean;
  availableForInteraction?: boolean;
  testId?: string;
  direction?: string;
  to?: string;
  skipFilter?: boolean;
  textClassName?: string;
}

const ContextMenuItem = (props: ContextMenuItemProps) => {
  const {
    text,
    checkedIconPlaceholder,
    active,
    availableForInteraction = true,
    testId,
    onClick,
    secondaryText,
    content,
    checked,
    disabled,
    direction = "right",
    to,
    textClassName,
  } = props;

  const handleClick = (
    event: React.SyntheticEvent<HTMLDivElement | HTMLAnchorElement>
  ) => {
    if (disabled) {
      return;
    }
    onClick && onClick(event);
  };

  const wrapByLink = (element: React.ReactElement) => {
    if (!to || disabled) {
      return element;
    }

    return (
      <a
        href={to}
        className={commonStyles.noFocus}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
        onKeyDown={event => handleClick(event)}
      >
        {element}
      </a>
    );
  };

  const element = (
    <div
      data-testid={testId}
      className={classNames(styles.item, {
        [styles.active]: active && availableForInteraction,
        [styles.interactionDisabled]: !availableForInteraction,
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
    >
      {direction === "left" && (
        <div className={styles.checkbox}>
          <Checkbox
            checked={Boolean(checked)}
            stopClickPropagation
          />
        </div>
      )}
      <div className={classNames(styles.text, textClassName)}>{text}</div>
      {secondaryText && (
        <div className={styles.secondaryText}>{secondaryText}</div>
      )}
      {content && <div className={styles.content}>{content}</div>}

      {(checked || checkedIconPlaceholder) && direction === "right" && (
        <div className={styles.checkedWrapper}>{checked && <CheckIcon />}</div>
      )}
    </div>
  );
  return wrapByLink(element);
};

export default ContextMenuItem;
