export interface ChannelTokens {
  [key: string]: {
    channel: string; // stores channel ID like database:<UUID>
    token: string;
  };
}

export interface SubscriptionEntityTypes {
  accountNames?: string[];
  organizationNames?: string[];
  loginNames?: string[];
}

export interface MappingEntityToChannel {
  accountNames: "acc";
  organizationNames: "org";
  loginNames: "login";
}

export enum EventTypes {
  AccountCreated = "account_created",
  AccountUpdated = "account_updated",
  AccountDeleted = "account_deleted",
  DatabaseCreated = "database_created",
  DatabaseUpdated = "database_updated",
  DatabaseDeleted = "database_deleted",
  EngineCreated = "engine_created",
  EngineUpdated = "engine_updated",
  EngineDeleted = "engine_deleted",
  CreditsRunOut = "credits_run_out",
  CreditExhausted = "credit_exhausted",
  LoginAccountsChanged = "login_accounts_changed",
  PaymentRegistered = "payment_registered",
}
