import {
  SUPPORT_ACCESS_QUERY_KEY,
  SUPPORT_ACCESS_SHORT_QUERY_KEY,
} from "./constants";

export const shouldInvalidateSupportAgent = (queryKey: unknown) => {
  return [SUPPORT_ACCESS_SHORT_QUERY_KEY, SUPPORT_ACCESS_QUERY_KEY].includes(
    queryKey as string
  );
};
