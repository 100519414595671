import {
  MappingEntityToChannel,
  SubscriptionEntityTypes,
} from "./websocket.types";

const EntityTypeToChannelTypeMap: MappingEntityToChannel = {
  accountNames: "acc",
  organizationNames: "org",
  loginNames: "login",
};

export const createChannelNamesFromEntities = (
  subscriptionEntities: SubscriptionEntityTypes
) => {
  const channelNames: string[] = [];
  const entityKeys = Object.keys(subscriptionEntities);

  entityKeys.forEach(key => {
    const channelType =
      EntityTypeToChannelTypeMap[key as keyof SubscriptionEntityTypes];
    subscriptionEntities[key as keyof SubscriptionEntityTypes]?.forEach(
      (entityName: string) => {
        channelNames.push(`${channelType}:${entityName}`);
      }
    );
  });

  return channelNames;
};
