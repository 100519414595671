import classNames from "classnames";
import { CSSProperties, ChangeEvent, FocusEvent, forwardRef } from "react";

import styles from "./styles.module.scss";

interface Props {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: CSSProperties;
  testId?: string;
  value?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { testId, className, ...rest } = props;

  const textareaClassName = classNames(styles.root, className);

  return (
    <textarea
      {...rest}
      ref={ref}
      className={textareaClassName}
      data-testid={testId}
    />
  );
});

export default Textarea;
