import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import { Login } from "./login.types";

export const getLoginByName = async (
  loginName: string
): Promise<Login | null> => {
  const sql =
    "select * from information_schema.logins where LOWER(login_name) = LOWER(:login_name)";

  try {
    const result = await systemEngineEnvironment.execute<Login>(sql, {
      namedParameters: { login_name: loginName },
    });

    const [response] = result;
    if (response.rows.length === 1) {
      return response.rows[0];
    }
    return null;
  } catch (e) {
    throw new ErrorWithTranslationKey("logins.fetch.error");
  }
};
