import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";

import StatusMessageQueueProvider, {
  StatusMessageQueueContextValue,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import { router } from "components/browserRouter";

import { queryClient } from "../queryClient";
import { AccessManagerContext, accessManager } from "./accessManager";

type Props = {
  onFinishRender: () => void;
};

const helmetContext = {};

// WARNING: This is a hack to get access to the status message queue from anywhere in the app
// For using it inside react rendering flow use useStatusMessageQueue hook
// eslint-disable-next-line
export let globalStatusMessageQueueInstance: StatusMessageQueueContextValue | null =
  null;

const App = (props: Props) => {
  const { onFinishRender } = props;

  useEffect(() => {
    onFinishRender && onFinishRender();
  }, [onFinishRender]);

  useEffect(() => {
    // @ts-ignore
    window?.pendo?.initialize({
      visitor: {
        id: "VISITOR-UNIQUE-ID", // pendo treats this as anonymous
      },
    });
  }, []);

  return (
    <AccessManagerContext.Provider value={accessManager}>
      <QueryClientProvider client={queryClient}>
        <StatusMessageQueueProvider
          onInit={(instance: StatusMessageQueueContextValue) => {
            globalStatusMessageQueueInstance = instance;
          }}
        >
          <HelmetProvider context={helmetContext}>
            <Helmet>
              <title>Firebolt Workspace</title>
            </Helmet>
            <RouterProvider router={router} />
          </HelmetProvider>
        </StatusMessageQueueProvider>
      </QueryClientProvider>
    </AccessManagerContext.Provider>
  );
};

export default App;
