import { systemEngineEnvironment } from "services/environment/systemEngine";

export const getUserByLogin = async (loginName: string) => {
  const sql = `select user_name, default_engine, default_database from information_schema.users where LOWER(login_name) = LOWER(?)`;

  const result = await systemEngineEnvironment.execute<{ userName: string }>(
    sql,
    {
      parameters: [loginName],
    }
  );

  const [response] = result;
  const rows = response.rows;
  return rows[0];
};
