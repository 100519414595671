import { Privilege } from "services/rbac/types";

import { ReactComponent as NoAccess } from "assets/images/errorScreen/noAccess.svg";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useAccessManager } from "components/App/accessManager";
import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";

const isAllowed = (
  privilege: Privilege,
  isAllowedTo: ReturnType<typeof useAccessManager>["isAllowedTo"],
  getAccount: ReturnType<typeof useCurrentAccount>["getAccount"]
) => {
  if (privilege.type === "account") {
    const account = getAccount();
    return isAllowedTo(
      privilege.type,
      account?.accountName as string,
      privilege.action
    );
  }
  return isAllowedTo(privilege.type, privilege.resource, privilege.action);
};

export const Restricted = (
  props: React.PropsWithChildren<{ privileges: Privilege[] }>
) => {
  const { privileges, children } = props;
  const { isAllowedTo } = useAccessManager();
  const { getAccount } = useCurrentAccount();
  const privilegeMatch = (privilege: Privilege) =>
    isAllowed(privilege, isAllowedTo, getAccount);

  if (privileges.length && !privileges.every(privilegeMatch)) {
    return (
      <ErrorScreen
        title="Access Denied"
        description="It seems you don’t currently have the access to this page.
      Contact your administrator for advice."
        image={<NoAccess />}
        imagePosition={ErrorScreenImagePosition.Top}
      />
    );
  }
  return <>{children}</>;
};

export const HideRestricted = (
  props: React.PropsWithChildren<{ privileges: Privilege[] }>
) => {
  const { privileges, children } = props;
  const { isAllowedTo } = useAccessManager();
  const { getAccount } = useCurrentAccount();

  const privilegeMatch = (privilege: Privilege) =>
    isAllowed(privilege, isAllowedTo, getAccount);
  if (privileges.length && !privileges.every(privilegeMatch)) {
    return null;
  }
  return <>{children}</>;
};
