import { useContext } from "react";

import { OnboardingContext } from "./context";

export const useOnboarding = () => {
  const { nextStep, setOnboardingStep, step, onboardingOrder, skip } =
    useContext(OnboardingContext);
  return {
    step,
    onboardingOrder,
    nextStep,
    setOnboardingStep,
    skip
  };
};
