import { systemEngineEnvironment } from "services/environment/systemEngine";

import { Privilege } from "./types";

export const getPrivileges = async (loginName: string) => {
  const sql = `
SELECT
OP.object_type,
OP.object_name,
OP.privilege_type,
AR.grantee,
FROM information_schema.object_privileges AS OP
JOIN information_schema.transitive_applicable_roles AS AR
ON OP.grantee = AR.role_name
WHERE AR.grantee = (
  select user_name from information_schema.users where LOWER(login_name) = LOWER(?)
)

`;

  const result = await systemEngineEnvironment.execute<Privilege>(sql, {
    parameters: [loginName],
  });

  const [response] = result;
  return response.rows;
};
