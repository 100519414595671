import { ErrorWithTranslationKey } from "utils/getSafeErrorMessage";

import UserFriendlyAPIError from "services/environment/UserFriendlyAPIError";
import { systemEngineEnvironment } from "services/environment/systemEngine";

export const deleteAccount = async (
  name: string,
  isCascade?: boolean
): Promise<boolean> => {
  try {
    const CASCADE = isCascade ? " CASCADE" : "";
    await systemEngineEnvironment.execute(`DROP ACCOUNT "${name}"${CASCADE};`);

    return true;
  } catch (e) {
    if (e instanceof UserFriendlyAPIError) {
      throw e;
    }

    throw new ErrorWithTranslationKey("accounts.form.delete.error");
  }
};
