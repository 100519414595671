import { SearchOptions } from "./types";

import styles from "./Select.module.scss";

const NO_RESULTS_TEXT = "No results";

type Props = {
  searchOptions?: SearchOptions;
};

export const EmptyState = (props: Props) => {
  const { searchOptions } = props;
  return (
    <div className={styles.noResults}>
      {searchOptions?.noResultsIcon ?? null}
      {searchOptions?.noResultsText ?? NO_RESULTS_TEXT}
    </div>
  );
};
