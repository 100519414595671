import { useRef, useState } from "react";

export const usePopover = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const openPopover = () => setOpen(true);
  const closePopover = () => setOpen(false);

  return {
    open,
    anchorRef,
    openPopover,
    closePopover,
  };
};
