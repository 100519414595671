export enum OnboardingStep {
  Empty = "Empty",
  Welcome = "Welcome",
  WelcomeCreditsDescription = "WelcomeCredits",
  LoadData = "LoadData",
}

export const onboardingOrder = [
  OnboardingStep.Welcome,
  OnboardingStep.WelcomeCreditsDescription,
  // OnboardingStep.LoadData,
];
