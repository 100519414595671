import axios from "axios";
import { statusCodes } from "utils/constants";

import { authService } from "services/auth";

import { UnauthorizedError } from "components/ErrorBoundary/UnauthorizedError";

const baseURL = `${process.env.REACT_APP_API_ENDPOINT_URL}/web/v3`;

const privateApi = axios.create({
  baseURL,
});

privateApi.interceptors.request.use(
  async config => {
    const token = await authService.getToken();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      await authService.redirectToLogout();
    }
    return config;
  },
  error => Promise.reject(error)
);

privateApi.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error;
    const originalRequest = error.config;
    if (
      response &&
      response.status === statusCodes.UNAUTHORIZED &&
      !originalRequest._retry
    ) {
      const token = await authService.getToken();
      if (token) {
        originalRequest._retry = true;
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        return privateApi.request(originalRequest);
      }
      await authService.redirectToLogout();

      await authService.redirectToLogout();
      return Promise.reject(error);
    }

    if (error.response && error.response.status === statusCodes.UNAUTHORIZED) {
      throw new UnauthorizedError(error);
    }

    return Promise.reject(error);
  }
);

export { privateApi };
