import { useParams } from "react-router-dom";

import { useAccounts } from "services/account/useAccounts";

import { getLatestAccount } from "./latestAccount";

export const useCurrentParamsAccount = () => {
  const accounts = useAccounts();
  const { accountName } = useParams();

  const parameterAccount = accounts.find(account => {
    return account.accountName === accountName;
  });

  if (parameterAccount) {
    return parameterAccount;
  }

  const latestAccountName = getLatestAccount();

  const latestAccount = accounts.find(account => {
    return account.accountName === latestAccountName;
  });

  if (latestAccount) {
    return latestAccount;
  }

  return accounts[0];
};
