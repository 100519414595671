import { Dialog, DialogContent, DialogProps, IconButton } from "@mui/material";
import { Suspense } from "react";

import { CrossIcon } from "components/Icons";
import LoadingOverlap from "components/LoadingOverlap";

import styles from "./styles.module.scss";

type Props = {
  image?: React.ReactElement;
  title?: React.ReactElement;
  body: React.ReactElement;
  header?: React.ReactElement;
  actions: React.ReactElement;
  TransitionProps?: DialogProps["TransitionProps"];
  dataTestId?: string;
  onClose?: () => void;
  open?: boolean;
  closeButton?: boolean;
};

export const OnboardingDialog = (props: Props) => {
  const {
    open = true,
    closeButton = true,
    image,
    title,
    header,
    body,
    actions,
    TransitionProps,
    dataTestId,
    onClose,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionProps={TransitionProps}
      classes={{ paper: styles.paper, root: styles.root }}
      data-testid={dataTestId}
      onClose={onClose}
    >
      <DialogContent
        classes={{
          root: styles.content,
        }}
      >
        <div className={styles.header}>
          {header}
          {closeButton && (
            <div className={styles.close}>
              <IconButton
                onClick={onClose}
                edge="start"
                size="small"
                data-testid="close-onboarding-icon"
              >
                <CrossIcon />
              </IconButton>
            </div>
          )}
        </div>
        <Suspense fallback={<LoadingOverlap isLoading />}>
          <div className={styles.top}>
            {image && <div className={styles.image}>{image}</div>}
            {title && <h2 className={styles.title}>{title}</h2>}
            {body && <div className={styles.body}>{body}</div>}
          </div>
        </Suspense>
      </DialogContent>
      <div className={styles.footer}>
        <div className={styles.actions}>{actions}</div>
      </div>
    </Dialog>
  );
};
