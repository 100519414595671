import { QueryClient } from "react-query";

import {
  accountsQueryKey,
  myAccountsQueryKey,
} from "services/account/constants";

export const updateAccounts = async ({
  queryClient,
}: {
  queryClient: QueryClient;
}): Promise<void> => {
  await Promise.all([
    queryClient.cancelQueries([myAccountsQueryKey]),
    queryClient.cancelQueries([accountsQueryKey]),
  ]);
  await Promise.all([
    queryClient.invalidateQueries([myAccountsQueryKey]),
    queryClient.invalidateQueries([accountsQueryKey]),
  ]);
};
