import { TFunction } from "i18next";

export class ErrorWithTranslationKey extends Error {
  public message: string;

  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export const getSafeErrorMessage = (
  error: Error | ErrorWithTranslationKey,
  t?: TFunction
): string => {
  if (error instanceof ErrorWithTranslationKey) {
    if (!t) throw new Error("provide translation function");

    return t(error.message);
  }
  return error.message;
};
