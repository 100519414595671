import { TooltipProps } from "@mui/material";
import classNames from "classnames";
import React, { ReactElement, useEffect, useRef } from "react";
import { clickOnSpaceOrEnterPress } from "utils/keyboardUtils";

import Tooltip from "components/Tooltip";

import commonStyles from "styles/common.module.scss";
import styles from "./ActionMenuItem.module.scss";

export enum ActionMenuItemSize {
  Default = "Default",
  Mid = "Mid",
}

export type ActionMenuItemProps = {
  text?: string | React.ReactNode;
  icon?: ReactElement;
  endIcon?: ReactElement;
  selectedIcon?: ReactElement;
  tooltip?: string;
  onClick?: (e: React.MouseEvent) => void;
  selected?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  size?: ActionMenuItemSize;
  className?: string;
  classes?: { [key: string]: string };
  testId?: string;
  showEndIconOnlyOnHover?: boolean;
  tooltipClasses?: TooltipProps["classes"];
  to?: string;
  title?: string;
  tooltipPlacement?: TooltipProps["placement"];
  tabIndex?: number;
};

const ActionMenuItem: React.FC<ActionMenuItemProps> = props => {
  const {
    text,
    icon,
    endIcon,
    selectedIcon,
    tooltip,
    tooltipClasses,
    onClick,
    selected,
    autoFocus = false,
    disabled,
    buttons,
    size = ActionMenuItemSize.Default,
    className,
    classes = {},
    showEndIconOnlyOnHover,
    to,
    title,
    testId = null,
    tabIndex = 0,
    tooltipPlacement,
  } = props;

  const element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (autoFocus && element.current) {
      element.current.focus();
    }
  }, [autoFocus]);

  const wrapByLink = (element: React.ReactElement) => {
    if (!to || disabled) {
      return element;
    }

    return (
      <a
        href={to}
        className={commonStyles.noFocus}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
        onKeyDown={event => clickOnSpaceOrEnterPress(event)}
      >
        {element}
      </a>
    );
  };

  const endIconClassNames = classNames(styles.iconContainer, {
    [styles.showOnlyOnHover]: showEndIconOnlyOnHover,
  });

  const testAttributes = testId
    ? {
        "data-testid": `${testId}`,
      }
    : {};

  const content = wrapByLink(
    <div
      {...testAttributes}
      className={classNames(
        styles.listItem,
        commonStyles.noFocus,
        styles[`size${size}`],
        className,
        {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
          [styles.buttons]: buttons,
          [classes.selected]: selected,
          [classes.disabled]: disabled,
          [classes.buttons]: buttons,
        }
      )}
      ref={element}
      title={title}
      tabIndex={tabIndex}
      onKeyPress={e => {
        if (disabled) {
          return;
        }
        clickOnSpaceOrEnterPress(e);
      }}
      onClick={!disabled && !buttons ? onClick : () => {}}
    >
      {icon && <span className={styles.iconContainer}>{icon}</span>}
      <span className={styles.text}>{text}</span>
      {buttons}
      <span className={styles.selectIconContainer}>
        {!!selectedIcon && selected && selectedIcon}
      </span>

      {endIcon && <span className={endIconClassNames}>{endIcon}</span>}
    </div>
  );

  if (!tooltip) {
    return content;
  }

  return (
    <Tooltip
      title={tooltip}
      disableInteractive={true}
      placement={tooltipPlacement || "left"}
      classes={tooltipClasses}
    >
      {content}
    </Tooltip>
  );
};

export default ActionMenuItem;
