import { systemEngineEnvironment } from "services/environment/systemEngine";

import { Role, SqlRole } from "./types";

export const getRoles = async (): Promise<Role[]> => {
  const result = await systemEngineEnvironment.execute<SqlRole>(
    `
SELECT
  ar.role_name,
  ar.is_grantable,
  ar.created,
  ARRAY_DISTINCT(ARRAY_AGG(
    ar.grantee
  )) as grantees,

  ARRAY_DISTINCT(ARRAY_AGG(
    CASE
      WHEN op.privilege_type IS NOT NULL
    THEN
      [
        op.privilege_type,
        op.object_type,
        op.object_catalog,
        op.object_schema,
        op.object_name,
        op.is_grantable,
        op.created::text
      ]
    ELSE
      NULL
    END
)) as privileges

FROM

information_schema.applicable_roles ar

LEFT JOIN
  information_schema.object_privileges op
ON
op.grantee = ar.role_name

GROUP BY ALL
`
  );
  const [response] = result;

  return response.rows.map(role => {
    const privileges = role.privileges
      ? role.privileges.map(privilege => {
          if (!privilege) return {
            privilegeType: "",
            objectType: "",
            objectCatalog: "",
            objectSchema: "",
            objectName: "",
            isGrantable: "",
            created: "",
          };
          const [
            privilegeType,
            objectType,
            objectCatalog,
            objectSchema,
            objectName,
            isGrantable,
            created,
          ] = privilege;
          return {
            privilegeType,
            objectType,
            objectCatalog,
            objectSchema,
            objectName,
            isGrantable,
            created,
          };
        })
      : [];

    return { ...role, privileges, grantees: role.grantees || [] };
  });
};
