import * as Sentry from "@sentry/browser";
import "flat-map-polyfill";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";

import "i18n/config";
import { authService } from "services/auth";

import App from "./components/App";
import { attachTestIdBreadcrumb } from "./utils/sentry-utils";

import "styles/fonts.scss";
import "styles/reset.scss";

if (
  ["production", "staging"].includes(
    process.env.REACT_APP_NODE_ENV as string
  ) &&
  process.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    environment: new URL(process.env.REACT_APP_API_ENDPOINT_URL as string).host,
    beforeBreadcrumb(breadcrumb, hint) {
      switch (breadcrumb.category) {
        case "ui.click":
          return attachTestIdBreadcrumb(breadcrumb, hint);
        default:
          return breadcrumb;
      }
    },
    ignoreErrors: [
      "top.GLOBALS",
      "LaunchDarklyFlagFetchError",
      "ChunkLoadError",
      "Loading CSS chunk",
    ],
    denyUrls: [
      /\/(gtm|ga|analytics)\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}

const onFinishRender = () => {
  const skeleton = document.getElementById("initial-skeleton");
  if (skeleton) {
    skeleton.remove();
  }
};

const initApp = async () => {
  let LDProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <>{children}</>
  );

  await authService.initialize();

  if (process.env.REACT_APP_LD_CLIENT_SIDE_ID) {
    LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID as string,
      user: {
        key: process.env.REACT_APP_LD_GLOBAL_KEY as string,
        name: process.env.REACT_APP_LD_GLOBAL_NAME as string,
      },
    });
  }

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <LDProvider>
      <App onFinishRender={onFinishRender} />
    </LDProvider>
  );
};

initApp();
