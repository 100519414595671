import { useQuery } from "react-query";

import { useBillingV2Flag } from "featureFlags/hooks/useBillingV2Flag";
import { getOrganizationCredit } from "services/organization/getOrganizationCredit";

export const useOrganizationCredit = () => {
  const billingV2Flag = useBillingV2Flag();
  const { data, isFetching } = useQuery({
    queryKey: ["organizationCredit", billingV2Flag],
    queryFn: async () => {
      try {
        return await getOrganizationCredit(billingV2Flag ? 2 : 1);
      } catch (error) {
        return null;
      }
    },
    suspense: false,
  });
  return { data, isFetching };
};
