import classNames from "classnames";
import Highlighter from "react-highlight-words";

import styles from "./Highlighter.module.scss";

interface Props {
  searchWords: string[];
  textToHighlight: string;
  className?: string;
}

const CustomHighlighter = (props: Props) => {
  const { searchWords, textToHighlight, className } = props;
  return (
    <Highlighter
      highlightClassName={classNames(styles.highlightedText, className)}
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  );
};

export default CustomHighlighter;
