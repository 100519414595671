import { useTranslation } from "react-i18next";

import { useHasAdminRole } from "services/auth/useHasAdminRole";
import { AWS_MARKETPLACE_URL } from "services/globals";

import Button, { ButtonTemplate } from "components/Button/Button";
import ListView from "components/ListView/ListView";
import { OnboardingDialog } from "components/Onboarding/OnboardingDialog";

import styles from "./styles.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CreditsNotificationModal = (props: Props) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const hasAdminRole = useHasAdminRole();

  return (
    <OnboardingDialog
      open={open}
      onClose={onClose}
      dataTestId="onboarding-marketplace-dialog"
      header={<>{t("onboarding.connect_marketplace.no_credits")}</>}
      body={
        <div className={styles.info}>
          {t("onboarding.connect_marketplace.info")}
        </div>
      }
      actions={
        <ListView
          spacingPx={4}
          direction="horizontal"
          justifyContent
          equalElemWidth
        >
          {hasAdminRole && (
            <Button
              dataTestid="connect-marketplace"
              text={t("onboarding.connect_marketplace.connect")}
              template={ButtonTemplate.Primary}
              onClick={() => {
                document.location.href = AWS_MARKETPLACE_URL;
              }}
              fullWidth
            />
          )}
          <Button
            text={t("onboarding.connect_marketplace.connect_later")}
            template={ButtonTemplate.Ghost}
            onClick={onClose}
            dataTestid="connect-marketplace-later"
            fullWidth
          />
        </ListView>
      }
    />
  );
};
