import React, { useEffect } from "react";

import styles from "./ContextMenu.module.scss";

interface ContextMenuItemsGroupProps {
  children: React.ReactNode;
  maxHeight?: number;

  // eslint-disable-next-line react/no-unused-prop-types -- required to recognize the group in ContextMenu component
  isGroup: true;
}

const ContextMenuItemsGroup = (props: ContextMenuItemsGroupProps) => {
  const { children } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current === null) return;

    const checkedChild = ref.current.querySelector(`.${styles.checked}`);
    if (checkedChild) {
      checkedChild.scrollIntoView({ block: "center" });
    }
  }, []);

  useEffect(() => {
    if (ref.current === null) return;

    const activeChild = ref.current.querySelector(`.${styles.active}`);

    if (activeChild) {
      activeChild.scrollIntoView({ block: "center" });
    }
  }, [children]);

  return (
    <div
      ref={ref}
      className={styles.itemsGroup}
      style={{
        maxHeight: `${props.maxHeight}px`,
      }}
    >
      {children}
    </div>
  );
};

export default ContextMenuItemsGroup;
