import { Centrifuge } from "centrifuge";

export const clearSubscriptions = (client: Centrifuge | undefined) => {
  if (!client) return;
  const existingSubscriptions = client.subscriptions();
  for (const sub in existingSubscriptions) {
    const subscription = existingSubscriptions[sub];
    subscription.unsubscribe();
    client.removeSubscription(subscription);
  }
};
