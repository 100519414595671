import { PublicationContext } from "centrifuge";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useOrganization } from "hooks/useOrganization";
import { authService } from "services/auth";
import { useHasAdminRole } from "services/auth/useHasAdminRole";
import { AWS_MARKETPLACE_URL } from "services/globals";
import { useOrganizationCredit } from "services/organization/useOrganizationCredit";

import Button, { ButtonSize, ButtonTemplate } from "components/Button/Button";
import { useWebsocket } from "components/topLevel/Websocket/useWebsocket";
import { EventTypes } from "components/topLevel/Websocket/websocket.types";

import commonStyles from "styles/common.module.scss";
import { CreditsNotificationModal } from "./CreditsNotificationModal";

import styles from "./styles.module.scss";

const Banner = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { data: organizationBalance } = useOrganizationCredit();
  const hasAdminRole = useHasAdminRole();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setOpen(true);
  };

  const { getClient, getChannelTokens } = useWebsocket();
  const client = getClient();
  const tokens = getChannelTokens();
  const key = `org:${authService.organizationName}`;
  const channel = tokens[key]?.channel;

  useEffect(() => {
    if (hasAdminRole && client && channel) {
      const subscription = client.getSubscription(channel);
      if (subscription) {
        const handler = (ctx: PublicationContext) => {
          if (
            ctx?.data?.type === EventTypes.CreditsRunOut ||
            ctx?.data?.type === EventTypes.CreditExhausted
          ) {
            setOpen(true);
          }
        };
        subscription.addListener("publication", handler);
        return () => {
          subscription.removeListener("publication", handler);
        };
      }
    }
  }, [client, channel, hasAdminRole, setOpen]);

  if (!organizationBalance) {
    return null;
  }

  if (organizationBalance && organizationBalance.availableCredit > 0) {
    return (
      <CreditsNotificationModal
        onClose={handleCloseModal}
        open={open}
      />
    );
  }

  return (
    <>
      <div className={styles.banner}>
        <div className={styles.banner__info}>
          {t("onboarding.connect_marketplace.no_credits")}{" "}
          {hasAdminRole ? (
            <>
              <a
                onClick={handleOpenModal}
                data-testid="banner-learn-more"
                className={classNames(
                  commonStyles.btn_link_medium,
                  styles.learn
                )}
              >
                {t("onboarding.connect_marketplace.learn_more")}
              </a>
              <Button
                dataTestid="banner-connect-marketplace"
                text={t("onboarding.connect_marketplace.connect")}
                onClick={() => {
                  document.location.href = AWS_MARKETPLACE_URL;
                }}
                size={ButtonSize.Medium}
                template={ButtonTemplate.Primary}
              />
            </>
          ) : (
            t("onboarding.connect_marketplace.contact_admin")
          )}
        </div>
      </div>
      <CreditsNotificationModal
        onClose={handleCloseModal}
        open={open}
      />
    </>
  );
};

export const CreditsBanner = () => {
  const { data: organization } = useOrganization();
  if (organization?.isPaymentRegistered) {
    return null;
  }
  return <Banner />;
};
