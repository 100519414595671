import { Navigate } from "react-router-dom";

import { useHasAccountAdminRole } from "services/auth/useHasAccountAdminRole";
import { useHasAdminRole } from "services/auth/useHasAdminRole";

export const adminRestricted = ({
  OrganizationAdminComponent,
  AccountAdminComponent,
}: {
  OrganizationAdminComponent: React.ComponentType;
  AccountAdminComponent: React.ComponentType;
}) => {
  return (props: React.ComponentProps<typeof OrganizationAdminComponent>) => {
    const hasAdminRole = useHasAdminRole();
    const hasAccountAdminRole = useHasAccountAdminRole();
    if (hasAdminRole) {
      return <OrganizationAdminComponent {...props} />;
    }
    if (hasAccountAdminRole) {
      return <AccountAdminComponent {...props} />;
    }
    return <Navigate to="/" />;
  };
};
