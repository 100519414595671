import classNames from "classnames";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import Tooltip from "components/Tooltip";

import commonStyles from "styles/common.module.scss";
import sidebarStyles from "../MainSidebar.module.scss";
import styles from "./MenuItem.module.scss";

type Props = {
  icon?: ReactElement;
  selectedIcon?: ReactElement;
  tooltip?: string;
  onClick?: (e?: any) => void;
  selected?: boolean;
  disabled?: boolean;
  to?: string;
  testId?: string;
  className?: string;
};

const MenuItem: React.FunctionComponent<Props> = props => {
  const { icon, tooltip, onClick, selected, disabled, to, testId, className } =
    props;

  const wrapByLink = (element: React.ReactElement, tooltip?: string) => {
    if (!to || disabled) {
      return React.cloneElement(element, { "data-testid": testId });
    }

    return (
      <Link
        to={to}
        data-testid={testId}
        className={commonStyles.noFocus}
      >
        {tooltip ? (
          <Tooltip
            disableInteractive
            title={tooltip}
            placement="right"
            classes={{
              popper: classNames(
                sidebarStyles.menuItemTooltip,
                sidebarStyles.forced
              ),
            }}
          >
            {element}
          </Tooltip>
        ) : (
          element
        )}
      </Link>
    );
  };

  return wrapByLink(
    <div
      className={classNames(
        styles.listItem,
        commonStyles.noFocus,
        {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
        },
        className
      )}
      onClick={!disabled ? onClick : () => {}}
    >
      {icon}
    </div>,
    tooltip
  );
};

export default MenuItem;
