import classNames from "classnames";
import React, { ReactNode, useEffect, useMemo, useRef } from "react";

import { ContextMenuItemProps } from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";

import styles from "./ContextMenu.module.scss";

interface ContextMenuProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

type Indexes = Array<number | Indexes>;

/**
Map children origin indexes to clickable items indexes
@return {Indexes}
Index in result is the index of the clickable item.
The value by index is the path to the clickable item in original children array
 */
const mapIndexes = (
  children: React.ReactNode | React.ReactNode[],
  parentIndex: number | null
): Indexes => {
  const result: Indexes = [];

  React.Children.forEach(children, (child, index) => {
    const childElement = child;

    if (!React.isValidElement(childElement)) {
      return;
    }

    if (childElement.props.isGroup) {
      const groupChildren = (childElement as React.ReactElement).props.children;
      result.push(...mapIndexes(groupChildren, index));
      return;
    }

    // only clickable items
    if (childElement.props.onClick) {
      result.push(parentIndex !== null ? [parentIndex, index] : index);
    }
  });

  return result;
};

const ContextMenu = (props: ContextMenuProps) => {
  const { children: propChildren, className } = props;

  const children: ReactNode[] = useMemo(() => {
    return Array.isArray(propChildren) ? propChildren : [propChildren];
  }, [propChildren]);

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const clickableItemsIndexes = useRef<Indexes>([]);

  useEffect(() => {
    clickableItemsIndexes.current = mapIndexes(children, null);

    // check if activeIndex is still valid
    if (activeIndex !== null) {
      if (activeIndex >= clickableItemsIndexes.current.length) {
        setActiveIndex(null);
      }
    }
  }, [propChildren, activeIndex, children]);

  const handleKeyDown = (e: KeyboardEvent) => {
    const clickableItemsLength = clickableItemsIndexes.current.length;

    if (!children || !Array.isArray(children)) {
      return;
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      e.stopPropagation();
      if (activeIndex === null) {
        setActiveIndex(0);
        return;
      }

      if (activeIndex < clickableItemsLength - 1) {
        setActiveIndex(activeIndex + 1);
      }
    }
    if (e.key === "ArrowUp") {
      e.preventDefault();
      e.stopPropagation();
      if (activeIndex === null) {
        setActiveIndex(clickableItemsLength - 1);
        return;
      }

      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    }

    if (e.key === "Enter" && activeIndex !== null) {
      e.stopPropagation();
      const childPath = clickableItemsIndexes.current[activeIndex];

      if (Array.isArray(childPath)) {
        const [parentIndex, index] = childPath;

        if (typeof parentIndex === "number" && typeof index === "number") {
          const groupItem = children[parentIndex];

          if (React.isValidElement(groupItem)) {
            const groupChildren = groupItem.props.children;
            const item = groupChildren[index] as React.ReactNode;

            if (React.isValidElement(item) && item.props.onClick) {
              setTimeout(() => {
                item.props.onClick(e);
              }, 0);
            }
          }
        }
      } else {
        const clickableItem = children[childPath];

        if (
          React.isValidElement(clickableItem) &&
          clickableItem.props.onClick
        ) {
          setTimeout(() => {
            clickableItem.props.onClick(e);
          }, 0);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  if (!children) {
    return null;
  }

  const activeItemPath =
    activeIndex !== null ? clickableItemsIndexes.current[activeIndex] : null;

  const renderItem = (
    child: React.ReactElement<ContextMenuItemProps>,
    path: number | [number, number]
  ) => {
    if (React.isValidElement(child)) {
      const isActive =
        Array.isArray(activeItemPath) && Array.isArray(path)
          ? activeItemPath[0] === path[0] && activeItemPath[1] === path[1]
          : activeItemPath === path;

      return React.cloneElement(child, {
        active: isActive,
        // TODO add key
      });
    }

    return child;
  };

  return (
    <div className={classNames(styles.contextMenu, className)}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          if (child.props.isGroup) {
            return (
              <ContextMenuItemsGroup
                // TODO add key
                maxHeight={child.props.maxHeight}
                isGroup
              >
                {React.Children.map(
                  child.props.children,
                  (groupChild, groupChildIndex) => {
                    return renderItem(groupChild, [index, groupChildIndex]);
                  }
                )}
              </ContextMenuItemsGroup>
            );
          }

          return renderItem(
            child as React.ReactElement<ContextMenuItemProps>,
            index
          );
        }
      })}
    </div>
  );
};

export default ContextMenu;
